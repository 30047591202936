import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import Api from "../../helper/api";

const ValidatePhoneScreen = () => {

    //component state
    const [serverError, setServerError] = useState("")
    const navigate = useNavigate()

    //handle form
    const {register,formState: { errors }, handleSubmit} = useForm()

    const handleFormSubmit = async (data) => {
        const api = new Api()

        try{
            await api.verifyPhoneNumber(data.code)
            navigate(0)
        } catch(error) {
            setServerError(error.message)
        }

    }

    return(
        <div className={"connection_wrapper"}>
            <div className={"connection_box"}>
                <h1>SMS Validation</h1>
                <form id={"connection_form"} onSubmit={handleSubmit(handleFormSubmit)}>

                    <div className={"form_row_wrapper type1"}>
                        <input type={"text"} name={"code"} id={"code"} placeholder={"Code"}  {...register("code", {required : "Veuillez renseigner le code de validation" })} />
                        <div className={"error"}>{errors.code && errors.code.message}</div>
                    </div>

                    <div className={"error text-center"}>{serverError}</div>

                    <div className={"form_row_wrapper type1"}>
                        <input type={"submit"} value={"Valider"}/>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default ValidatePhoneScreen