import React, {useEffect, useState, Suspense} from "react";
import {Await, useLoaderData, useNavigate, useRevalidator} from "react-router-dom";

import {GenericEmptyItem, SingleMatchBlock} from "../../other/GenericBlock";
import SlidingPanel from "../_sliding_panel";
import ProfilDataBlock from "../../other/ProfilDataBlock";
import SingleItemHeader from "../_singleItem/SingleItemHeader";
import SingleItemMatch from "../_singleItem/SingleItemMatch";
import SingleItemDetails from "../_singleItem/SingleItemDetails";
import SingleItemCollaborateur from "../_singleItem/SingleItemCollaborateur";
import SingleItemMessages from "../_singleItem/SingleItemMessages";
import Api from "../../helper/api";

const SingleMission = () => {

    //state
    const [mission, setMission] = useState([])

    //filter
    const [showSlidingPanelDetails, setShowSlidingPanelDetails] = useState(false);

    //loaders
    const {missionPromise, pendingMatchPromise, acceptedMatchPromise} = useLoaderData()

    //api
    const revalidator = useRevalidator()
    const navigate = useNavigate()
    const api = new Api()

    useEffect(() => {
        missionPromise.then((mission) => setMission(mission))
    })

    //set mission as ended
    const handleEndMission = () => {
        api.updateMission(mission.id,{closed : true}).then(() => {
            revalidator.revalidate()
        })
    }

    //delete mission
    const handleDeleteMission = () => {
        api.deleteMission(mission.id).then(() => {
            navigate("/missions/")
        })
    }

    //stop matching
    const handleStopMatching = () => {
        api.updateMission(mission.id,{linkingOpened : false}).then(() => {
            revalidator.revalidate()
        })
    }

    return(
        <div className={"single_item_wrapper"} id={"single_mission_wrapper"}>
            <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                <Await resolve={missionPromise}>

                    <SingleItemHeader singleItem={mission} setShowSlidingPanelDetails={setShowSlidingPanelDetails}/>

                    <Await resolve={pendingMatchPromise}>
                        {(pendingMatchs) => <SingleItemMatch pendingMatchs={pendingMatchs.length} itemId={mission.id} type={"mission"}/>}
                    </Await>

                    <Await resolve={acceptedMatchPromise}>
                        {(acceptedMatchs) => <SingleItemCollaborateur type={"mission"} linkings={acceptedMatchs} showStopMatchingCta={mission.linkingOpened && !mission.closed} stopMatching={handleStopMatching}/>}
                    </Await>

                    <Await resolve={acceptedMatchPromise}>
                        <SingleItemMessages type={"mission"}/>
                    </Await>

                    <div id={"single_mission_cta"}>
                        {!mission.closed &&
                            <>
                                <button className={"cta blue full"} onClick={handleEndMission}>Cette mission est terminée</button>
                                <button className={"cta full"} onClick={handleDeleteMission}>Supprimer</button>
                            </>
                        }
                        {mission.closed &&
                            <button className={"cta transparent full"}>Cette mission est terminée</button>
                        }
                    </div>

                    <SlidingPanel show={showSlidingPanelDetails} handleClose={() => setShowSlidingPanelDetails(false)}>
                        <SingleItemDetails singleItem={mission}/>
                    </SlidingPanel>

                </Await>
            </Suspense>
        </div>
    )
}

export default SingleMission