import React, {useState, useEffect, useCallback} from "react";
import {useOutletContext, useRevalidator} from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSetState } from "react-use";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {useDropzone} from "react-dropzone";

import Api from "../../helper/api";
import ProfilDataBlock from "../../other/ProfilDataBlock";
import AddressAutoComplete from "../../form/addressAutoComplete";
import FormExperience from "../../form/formExperience";
import FormSkill from "../../form/formSkill";
import FormSpecialization from "../../form/formSpecialization";
import FormActivity from "../../form/formActivity";
import FormJob from "../../form/formJob";
import FormEducation from "../../form/formEducation";
import FormAssociation from "../../form/formAssociation";


const ProfileMission = (props) => {

    //props
    //const {user} = props;
    const {user} = useOutletContext();

    //handle form
    const {register,formState: { errors }, handleSubmit, setValue, getValues, watch} = useForm()
    const {register : registerPresentation ,formState: { errors : errorsPresentation }, handleSubmit : handleSubmitPresentation, setValue : setValuePresentation} = useForm()

    //handle form submit
    let revalidator = useRevalidator();

    //date picker
    const [datePicker, setDatePicker] = useState("");

    //Professionnal card upload
    const [professionnalCard, setProfessionnalCard] = useSetState({
        name : "",
        fileObject : null
    });

    const handleFileDrop = useCallback(async acceptedFiles => {

        setProfessionnalCard({
            fileObject : acceptedFiles[0],
            name : acceptedFiles[0].name
        })

        await api.setUserProfessionnalCard(acceptedFiles[0])

    }, [])

    const {getRootProps, getInputProps} = useDropzone({
        onDrop : handleFileDrop,
        accept : {
            'image/*': ['.png,.jpg,.jpeg'],
            'pdf': ['.pdf'],
        }
    })

    //Avatar upload
    const handleAvatarUpload = async (e) => {
        if (e.target.files[0] !== undefined) {
            await api.setUserAvatar(e.target.files[0])
            revalidator.revalidate()
        }
    }

    //api
    const api = new Api();

    //form data
    const [formData, setFormData] = useSetState({
        barList : []
    })

    //form update
    const handleUpdateDetails = async (data) => {
        //console.log(data)
        data.averageHourlyRate = parseFloat(data.averageHourlyRate)
        data.dailyRate = parseFloat(data.dailyRate)
        data.halfDayRate = parseFloat(data.halfDayRate)

        await api.UpdateUser(user.id, data)
    }

    const handleUpdatePresentation = async (data) => {
        await api.UpdateUser(user.id, data)
    }

    useEffect(() => {

        const fetchData = async () => {
            //load api data
            const barList = await api.getBarList();

            setFormData({
                barList : barList
            })
        }

        //console.log(user)

        //set value of personnal details form
        setValue("firstname",user.firstname??"")
        setValue("lastname",user.lastname??"")
        setValue("phone",user.phone??"")
        setValue("email",user.email??"")
        setValue("oathDate",user.oathDate??"")
        setDatePicker(user.oathDate ? new Date(user.oathDate) : "") //set datepicker value
        setValue("location",user.location??"")
        setValue("averageHourlyRate",user.averageHourlyRate??"")
        setValue("dailyRate",user.dailyRate??"")
        setValue("halfDayRate",user.halfDayRate??"")
        setValue("cabinet",user.cabinet??"")

        //professionalCertificateName
        if(user.professionalCertificateName){
            setProfessionnalCard({name : user.professionalCertificateName.replace('professional_certificate/', '')})
        }

        //présentation
        setValuePresentation("description",user.description ?? "")

        fetchData()

    },[])

    return(
        <div id={"profile_wrapper"} className={"mission_profile"}>
            <div className={"bloc_title_info"}>
                <h2>Bienvenue sur EsterLaw, {user.firstname}</h2>
                <p>Pour un maximum de matchs, présentez un profil très complet en quelques clics et/ou accéder à la communauté en complétant les champs obligatoires.</p>
            </div>

            <div id={"personnal_information_details"} className={"profil_block"}>
                <form onSubmit={handleSubmit(handleUpdateDetails)}>
                    <div className={"upload_wrapper form_row_wrapper"} id={"avatar_upload_wrapper"}>
                        <input type={"file"} name={"profil_picture"} id={"profil_picture"} accept="image/png, image/jpeg" onChange={handleAvatarUpload}/>
                        <label htmlFor={"profil_picture"} className={"picture"}>
                            {user.imageName && <img src={process.env.REACT_APP_API_URL+"/"+user.imageName}/> }
                            {!user.imageName && <div>{user.lastname.charAt(0)}{user.firstname.charAt(0)}</div> }
                        </label>
                        {/*<label htmlFor={"profil_picture"}>Télécharger une photo</label>*/}
                        {/*<button disabled={user.imageName ? false: true}>Supprimer</button>*/}
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label>Prénom *</label>
                        <input type={"text"} name={"firstname"} id={"firstname"} placeholder={"Prénom"} {...register("firstname",{required : "Champs obligatoire"})}/>
                        <div className={"error"}>{errors.firstname && errors.firstname.message}</div>
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label>Nom *</label>
                        <input type={"text"} name={"lastname"} id={"lastname"} placeholder={"Nom"} {...register("lastname",{required : "Champs obligatoire"})}/>
                        <div className={"error"}>{errors.lastname && errors.lastname.message}</div>
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label>Email *</label>
                        <input type={"email"} name={"email"} id={"email"} placeholder={"e-mail"} {...register("email",{required : "Champs obligatoire"})} disabled/>
                        <div className={"error"}>{errors.email && errors.email.message}</div>
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label>Numéro de téléphone *</label>
                        <input type={"text"} name={"phone"} id={"phone"} placeholder={"Numéro de téléphone"} {...register("phone",{required : "Champs obligatoire"})} disabled/>
                        <div className={"error"}>{errors.phone && errors.phone.message}</div>
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label>Barreau actuel *</label>
                        <select name={"bar"} id={"bar"} placeholder={"Type here"} defaultValue={""} {...register("bar", {required: "Champs obligatoire"})}>
                            <option value={""} disabled>Barreau actuel</option>
                            {formData.barList.map((item) => {
                                return (<option value={item.id} key={item.name + "_" + item.id}>{item.name}</option>)
                            })}
                        </select>
                        <div className={"error"}>{errors.bar && errors.bar.message}</div>
                    </div>
                    <div className={"form_row_wrapper type3 dropzone_wrapper"}>

                        {professionnalCard.name === "" &&
                            <div {...getRootProps()} className={"dropzone"}>
                                <input {...getInputProps()} name={"professionalCertificate"}/>
                                Ajouter carte professionnelle <br/>ou attestation de l'ordre
                            </div>
                        }

                        {professionnalCard.name !== "" &&
                            <div className={"dropzone_notice text-center success"}>
                                Carte ajoutée
                            </div>
                        }

                    </div>

                    <div className={"form_row_wrapper type3"}>

                        <label>Date de prestation de serment *</label>
                        <input type={"hidden"} name={"oathDate"} id={"oathDate"} {...register("oathDate", {required: "Champs obligatoire"})}/>

                        <DatePicker
                            showYearDropdown
                            yearDropdownItemNumber={30}
                            placeholderText="Date de prestation de serment"
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                            selected={datePicker} onChange={(date) => {
                            setDatePicker(date??"")
                            setValue("oathDate", date?date.toISOString():"", { shouldValidate: true })
                        }}/>
                        <div className={"error"}>{errors.oathDate && errors.oathDate.message}</div>
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label>No immatriculation du cabinet (Siret) *</label>
                        <input type={"text"} name={"cabinet"} id={"cabinet"} placeholder={"No immatriculation du cabinet (Siret)"} {...register("cabinet", {required: "Champs obligatoire"})}/>
                        <div className={"error"}>{errors.cabinet && errors.cabinet.message}</div>
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label>Adresse professionnelle</label>
                        <AddressAutoComplete name={"location"} id={"location"} defaultValue={getValues("location")} errors={errors} register={register} setValue={(value) => setValue("location", value.address)}/>
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label>Taux horaire moyen</label>
                        <input type={"text"} name={"averageHourlyRate"} id={"averageHourlyRate"} placeholder={"Taux horaire moyen"} {...register("averageHourlyRate",{required : "Champs obligatoire"})}/>
                        <div className={"error"}>{errors.averageHourlyRate && errors.averageHourlyRate.message}</div>
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label>Tarif journalier</label>
                        <input type={"text"} name={"dailyRate"} id={"dailyRate"} placeholder={"Tarif journalier"} {...register("dailyRate",{required : "Champs obligatoire"})}/>
                        <div className={"error"}>{errors.dailyRate && errors.dailyRate.message}</div>
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label>Tarif demi-journée</label>
                        <input type={"text"} name={"halfDayRate"} id={"halfDayRate"} placeholder={"Tarif demi-journée"} {...register("halfDayRate",{required : "Champs obligatoire"})}/>
                        <div className={"error"}>{errors.halfDayRate && errors.halfDayRate.message}</div>
                    </div>
                    <div className={"text-right"}>
                        <button className={"cta full blue"}>Enregistrer les modifications</button>
                    </div>
                </form>
            </div>

            <ExperienceBlock user={user}/>

            <div id={"presentation_details"} className={"profil_block"}>
                <div className={"bloc_title_info"}>
                    <h2>Présentation : parlez-nous de vous</h2>
                    <p>Votre présentation est la pierre angulaire du match, donnez en quelques lignes à votre profil un maximum de chances lors des matchs</p>
                </div>

                <form onSubmit={handleSubmitPresentation(handleUpdatePresentation)}>
                    <div className={"form_row_wrapper type3"}>
                        <textarea name={"description"} placeholder={"Quelques mots..."} {...registerPresentation("description", {required : "Veuillez renseigner votre présentation"})}></textarea>
                        <div className="error">{errorsPresentation.description && errorsPresentation.description.message}</div>
                    </div>
                    <div className={"text-right"}>
                        <button className={"cta full blue"}>Enregistrer les modifications</button>
                    </div>
                </form>
            </div>

            <DisponibilityBlock user={user}/>

            <SkillsBlock user={user}/>

            <SpecializationBlock user={user}/>

            <ActivityBlock user={user}/>

            <JobBlock user={user}/>

            <FormationBlock user={user}/>

            <AssociativeActivityBlock user={user}/>

            <RessourcesBlock user={user}/>

        </div>
    )
}

const ExperienceBlock = (props) => {

    const handleFormSubmit = async (data) => {
        const api = new Api();
        await api.UpdateUser(props.user.id, data)
    }

    return(
        <div id={"experience_details"} className={"profil_block"}>

            <div className={"bloc_title_info"}>
                <h2>Expériences</h2>
                <p>Mettez en avant votre expérience, que vous soyez jeune avocat(e) ou expérimenté(e), avec une expertise internationale ou pas, notre algorithme vous proposera les missions qui vous correspondent.</p>
            </div>

            <div id={"addexperience_form"} className={"profil_block"}>
                <FormExperience user={props.user} onFormSubmit={handleFormSubmit}/>
            </div>

        </div>
    );
}

const DisponibilityBlock = (props) => {

    //form data
    const [formData, setFormData] = useSetState({
        disponibiltyList : [],
        collaborationList : [],
        radiusList : [],
        updateUserField : false
    })

    //conditionnal field
    const [showRadius, setShowRadius] = useState(false)
    const [showDistance, setShowDistance] = useState(false)

    //api
    const api = new Api();

    //handle form submit
    const {register,formState: { errors }, handleSubmit, setValue, watch, unregister} = useForm();

    //load form data
    useEffect(() => {

        const fetchData = async () => {
            const disponibilityListData = await api.getAvailability();
            const collaborationTypeListData = await api.getMissionCollaborationTypesList();
            const radiusListData = await api.getRadiusList();

            setFormData({
                disponibiltyList : disponibilityListData,
                collaborationList : collaborationTypeListData,
                radiusList: radiusListData,
                updateUserField : true
            })
        }

        fetchData()
    }, [])

    //set user value
    useEffect(() => {

        if(!formData.updateUserField)
            return;

        //set form value from user
        setValue('collaborationType', props.user.collaborationType? props.user.collaborationType.id:"",{shouldValidate: true})
        setValue('available', props.user.available.id??"")

    }, [formData.updateUserField])

    //handle change of collaboration type field
    useEffect(() => {

        const colType = parseInt(watch("collaborationType"))

        if(colType === 1 || colType === 3){
            setShowRadius(true)
            setValue("radius", props.user.radius)
        }else{
            setShowRadius(false)
            unregister("radius")
            unregister("distance")
        }
    }, [watch("collaborationType")])


    //handleRadius Change
    useEffect(() => {

        const radius = parseInt(watch("radius"))


        if(radius === 2){
            setShowDistance(true)
            setValue("distance", props.user.distance)
        }else{
            setShowDistance(false)
            unregister("distance")
        }
    }, [watch("radius")])

    const handleFormSubmit = async (data) => {
        await api.UpdateUser(props.user.id, data)
    }


    return(
        <div id={"availability_details"} className={"profil_block"}>
            <div className={"bloc_title_info"}>
                <h2>Disponibilité</h2>
                <p>Optez pour le mode de travail de votre choix. Par défaut, notre algorithme vous considèrera disponible. Par la suite, pensez à signaler vos périodes d'indisponibilité dans le menu profil.</p>
            </div>

            <form onSubmit={handleSubmit(handleFormSubmit)}>

                <div className={"form_row_wrapper type3"}>
                    <label>Choisir un mode de collaboration</label>
                    <select name={"collaborationType"} id={"collaboration_type"}  {...register("collaborationType", {required: "Veuillez renseigner ce champs pour continuer"})}>
                        <option value={""} disabled>Type de collaboration</option>
                        {formData.collaborationList.map((item) => {
                            return (<option value={item.id} key={item.name + "_" + item.id}>{item.name}</option>)
                        })}
                    </select>
                </div>

                {showRadius &&
                    <>
                        <div className={"form_row_wrapper type3"}>
                            <label>Périmètre</label>
                            <select name={"radius"} id={"radius"} {...register("radius", {required: "Veuillez renseigner ce champs pour continuer"})}>
                                {formData.radiusList.map((item) => {
                                    return (<option value={item.id} key={item.name + "_" + item.id}>{item.name}</option>)
                                })}
                            </select>
                        </div>

                        {showDistance &&
                            <div className={"form_row_wrapper type3"}>
                                <label>Distance (km)</label>
                                <input type={"text"} name={"distance"} id={"distance"} {...register("distance", {required: "Veuillez renseigner ce champs pour continuer"})}/>
                            </div>
                        }
                    </>
                }

                <div className={"form_row_wrapper type3"}>
                    <label>Je suis disponible</label>
                    <select name={"available"} id={"available"}  {...register("available", {required: "Veuillez renseigner ce champs pour continuer"})}>
                        {formData.disponibiltyList.map((item) => {
                            return (<option value={item.id} key={item.name + "_" + item.id}>{item.name}</option>)
                        })}
                    </select>
                </div>

                {/*<div className={"availability_checkbox"}>
                    <div className={"title"}>Etes-vous disponible en ce moment ?</div>
                    <div className={"checkbox_availability_wrapper"}>
                        {formData.disponibiltyList.map((item, key) => {
                            return (
                                <div className={"checkbox_availability_item"} key={item.name + "_" + item.id}>
                                    <input type={"radio"} name={"availability"} id={`availability_${item.id}`} value={item.id} checked={item.id == watch("available")} {...register("available")}/>
                                    <label htmlFor={`availability_${item.id}`}>{item.name}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>*/}

                <div className={"text-right"}>
                    <button className={"cta full blue"}>Enregistrer les modifications</button>
                </div>
            </form>
        </div>
    )
}

const SkillsBlock = (props) => {

    //show / hide add form
    const [showAddForm, setShowAddForm] = useState(false)

    //if in edit mode set skill object to edit
    const [editItem, setEditItem] = useState(null)

    //Api
    const api = new Api();
    const revalidator = useRevalidator();

    //handle cancel form
    const handleCancel = () => {
        setEditItem(null)
        setShowAddForm(false)
    }

    //handle edit action
    const handleEdit = (item) => {
        setEditItem(item)
        setShowAddForm(true)
    }

    //handle delete action
    const deleteSkill = async (skill) => {
        await api.deleteUserSkill(skill.id)
        revalidator.revalidate()
    }

    //submit form
    const handleFormSubmit = async (data) => {

        let promise

        if(editItem === null)
            promise = api.createUserSkill(data.description, data.area.id, data.experience.id, data.countries, data.subareas)
        else
            promise = api.updateUserSkill(data.description, data.area.id, data.experience.id, data.countries, data.subareas, editItem.id)

        await promise.then(() => {
            //TODO success
            revalidator.revalidate()
            handleCancel()
        })
        .catch( error => {
            //TODO display error
        })
    }

    return(
        <div id={"skills_details"} className={"profil_block"}>
            <div className={"bloc_title_info"}>
                <h2>Compétences</h2>
                <p>Indiquez vos 3 principaux domaines d'expertise par ordre de préférence et précisez les sous-domaines.</p>
            </div>

            <div className={"skills_wrapper"}>

                {props.user.skills.map((item, i) => {
                    return(
                        <ProfilDataBlock title={item.area.name} experience={item.experience.name} international={item.countries} description={item.description} subareas={item.subareas} editCallBack={() => handleEdit(item)} deleteCallBack={() => deleteSkill(item)}/>
                    )
                })}

                {!showAddForm &&
                    <div className={"text-right"}>
                        <button className={"cta full blue reverse"} onClick={()=> setShowAddForm(true)}>+ Ajouter une compétence</button>
                    </div>
                }
            </div>

            {showAddForm &&
                <div id={"addskill_wrapper"}>
                    <div id={"addskill_form"} className={"profil_block"}>
                        <FormSkill user={props.user} onFormSubmit={handleFormSubmit} onFormCancel={handleCancel} skill={editItem}/>
                    </div>
                </div>
            }

        </div>
    )
}

const SpecializationBlock = (props) => {

    //show / hide add form
    const [showAddForm, setShowAddForm] = useState(false)

    //if in edit mode set skill object to edit
    const [editItem, setEditItem] = useState(null)

    //Api
    const api = new Api();
    const revalidator = useRevalidator();

    //handle cancel form
    const handleCancel = () => {
        setEditItem(null)
        setShowAddForm(false)
    }

    //handle edit action
    const handleEdit = (item) => {
        setEditItem(item)
        setShowAddForm(true)
    }

    //handle delete action
    const deleteSpecialization = async (specialization) => {
        await api.deleteUserSpesialization(specialization.id)
        revalidator.revalidate()
    }

    //submit form
    const handleFormSubmit = async (data) => {

        let promise

        if(editItem === null)
            promise = api.createUserSpesialization(data.description, data.specialization.id, data.experience.id)
        else
            promise =  api.updateUserSpesialization(data.description, data.specialization.id, data.experience.id, editItem.id)

        await promise.then(() => {
            //TODO success
            revalidator.revalidate()
            handleCancel()
        })
        .catch( error => {
            //TODO display error
        })
    }

    return(
        <div id={"specialization_details"} className={"profil_block"}>

                <div className={"bloc_title_info"}>
                    <h2>Spécialisations</h2>
                    <p>Listez vos certifications de spécialisation.</p>
                </div>

                <div className={"skills_wrapper"}>

                    {props.user.specializations.map((item, i) => {
                        return(
                            <ProfilDataBlock key={`specialization_data${i}`} title={item.specialization.name} experience={item.experience.name}  description={item.description} editCallBack={() => handleEdit(item)} deleteCallBack={() => deleteSpecialization(item)}/>
                        )
                    })}

                    {!showAddForm &&
                        <div className={"text-right"}>
                            <button className={"cta full blue reverse"} onClick={() => setShowAddForm(true)}>+ Ajouter une spécialisation</button>
                        </div>
                    }

                </div>

                {showAddForm &&
                    <div id={"addspecialization_wrapper"}>
                        {/*<div className={"bloc_title_info"}>
                        <h2>Présentation de la section</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. (5 compétences par ordre de préférence et sous-domaines)</p>
                    </div>*/}
        
                    <div id={"addspecialization_form"} className={"profil_block"}>
                        <FormSpecialization onFormSubmit={handleFormSubmit} onFormCancel={handleCancel} specialization={editItem}/>
                    </div>
        
                    </div>
                }

            </div>
    );
}

const ActivityBlock = (props) => {

    //show / hide add form
    const [showAddForm, setShowAddForm] = useState(false)

    //if in edit mode set skill object to edit
    const [editItem, setEditItem] = useState(null)

    //Api
    const api = new Api();
    const revalidator = useRevalidator();

    //handle cancel form
    const handleCancel = () => {
        setEditItem(null)
        setShowAddForm(false)
    }

    //handle edit action
    const handleEdit = (item) => {
        setEditItem(item)
        setShowAddForm(true)
    }

    //handle delete action
    const deleteActivity = async (activity) => {
        await api.deleteUserIndustry(activity.id)
        revalidator.revalidate()
    }

    //submit form
    const handleFormSubmit = async (data) => {

        let promise

        if(editItem === null)
            promise = api.createUserIndustry(data.description, data.industry.id, data.experience.id, data.countries)
        else
            promise =  api.updateUserIndustry(data.description, data.industry.id, data.experience.id, data.countries, editItem.id)

        await promise.then(() => {
            //TODO success
            revalidator.revalidate()
            handleCancel()
        })
        .catch( error => {
            //TODO display error
        })
    }

    return(
        <div id={"activity_details"} className={"profil_block"}>

            <div className={"bloc_title_info"}>
                <h2>Secteurs d’activité</h2>
                <p>Précisez vos secteurs d'activité de prédilection.</p>
            </div>

            <div className={"skills_wrapper"}>

                {props.user.industries.map((item, i) => {
                    return(<ProfilDataBlock key={`secteur_data${i}`} title={item.industry.name} experience={item.experience ? item.experience.name : null} international={item.countries} description={item.description} editCallBack={() => handleEdit(item)} deleteCallBack={() => deleteActivity(item)}/>)
                })}

                {!showAddForm &&
                    <div className={"text-right"}>
                        <button className={"cta full blue reverse"} onClick={() => setShowAddForm(true)}>+ Ajouter un secteur d'activité</button>
                    </div>
                }

            </div>

            {showAddForm &&
                <div id={"addactivity_wrapper"}>
                    <div id={"addactivity_form"} className={"profil_block"}>
                        <FormActivity user={props.user} onFormSubmit={handleFormSubmit} onFormCancel={handleCancel} activity={editItem}/>
                    </div>
                </div>
            }

        </div>
    );
}

const JobBlock = (props) => {

    //show / hide add form
    const [showAddForm, setShowAddForm] = useState(false)

    //if in edit mode set skill object to edit
    const [editItem, setEditItem] = useState(null)

    //Api
    const api = new Api();
    const revalidator = useRevalidator();

    //handle cancel form
    const handleCancel = () => {
        setEditItem(null)
        setShowAddForm(false)
    }

    //handle edit action
    const handleEdit = (item) => {
        setEditItem(item)
        setShowAddForm(true)
    }

    //handle delete action
    const deleteJob = async (job) => {
        await api.deleteUserJob(job.id)
        revalidator.revalidate()
    }

    //submit form
    const handleFormSubmit = async (data) => {

        let promise

        if(editItem === null)
            promise = api.createUserJob(data.title, data.description)
        else
            promise =  api.updateUserJob(data.title, data.description, editItem.id)

        await promise.then(() => {
            //TODO success
            revalidator.revalidate()
            handleCancel()
        })
        .catch( error => {
            //TODO display error
        })
    }

    return(
        <div id={"job_details"} className={"profil_block"}>

            <div className={"bloc_title_info"}>
                <h2>Parcours professionnel</h2>
                <p>Présentez votre parcours en précisant dates, postes et détails des postes / collaborations.</p>
            </div>

            <div className={"skills_wrapper"}>

                {props.user.jobs.map((item, i) => {
                    return(<ProfilDataBlock key={`job_data${i}`} title={item.title} description={item.description} editCallBack={() => handleEdit(item)} deleteCallBack={() => deleteJob(item)}/>)
                })}

                {!showAddForm &&
                    <div className={"text-right"}>
                        <button className={"cta full blue reverse"} onClick={() => setShowAddForm(true)}>+ Ajouter un parcours</button>
                    </div>
                }

            </div>

            {showAddForm &&
                <div id={"addactivity_wrapper"}>
                    <div id={"addjob_form"} className={"profil_block"}>
                        <FormJob user={props.user} onFormSubmit={handleFormSubmit} onFormCancel={handleCancel} job={editItem}/>
                    </div>

                </div>
            }

        </div>
    );
}

const FormationBlock = (props) => {

    //show / hide add form
    const [showAddForm, setShowAddForm] = useState(false)

    //if in edit mode set skill object to edit
    const [editItem, setEditItem] = useState(null)

    //Api
    const api = new Api();
    const revalidator = useRevalidator();

    //handle cancel form
    const handleCancel = () => {
        setEditItem(null)
        setShowAddForm(false)
    }

    //handle edit action
    const handleEdit = (item) => {
        setEditItem(item)
        setShowAddForm(true)
    }

    //handle delete action
    const deleteEducation = async (job) => {
        await api.deleteUserEducation(job.id)
        revalidator.revalidate()
    }

    //submit form
    const handleFormSubmit = async (data) => {

        let promise

        if(editItem === null)
            promise = api.createUserEducation(data.title, data.description)
        else
            promise =  api.updateUserEducation(data.title, data.description, editItem.id)

        await promise.then(() => {
            //TODO success
            revalidator.revalidate()
            handleCancel()
        })
            .catch( error => {
                //TODO display error
            })
    }

    return(
        <div id={"job_details"} className={"profil_block"}>

            <div className={"bloc_title_info"}>
                <h2>Formation et certifications</h2>
                <p>Indiquez vos diplômes et certifications.</p>
            </div>

            <div className={"skills_wrapper"}>

                {props.user.educations.map((item, i) => {
                    return(<ProfilDataBlock key={`education_data${i}`} title={item.title} description={item.description} editCallBack={() => handleEdit(item)} deleteCallBack={() => deleteEducation(item)}/>)
                })}

                {!showAddForm &&
                    <div className={"text-right"}>
                        <button className={"cta full blue reverse"} onClick={() => setShowAddForm(true)}>+ Ajouter une formation</button>
                    </div>
                }

            </div>

            {showAddForm &&
                <div id={"addactivity_wrapper"}>

                    <div id={"addactivity_form"} className={"profil_block"}>
                        <FormEducation user={props.user} onFormSubmit={handleFormSubmit} onFormCancel={handleCancel} education={editItem}/>
                    </div>

                </div>
            }

        </div>
    );
}

const AssociativeActivityBlock = (props) => {

    //show / hide add form
    const [showAddForm, setShowAddForm] = useState(false)

    //if in edit mode set skill object to edit
    const [editItem, setEditItem] = useState(null)

    //Api
    const api = new Api();
    const revalidator = useRevalidator();

    //handle cancel form
    const handleCancel = () => {
        setEditItem(null)
        setShowAddForm(false)
    }

    //handle edit action
    const handleEdit = (item) => {
        setEditItem(item)
        setShowAddForm(true)
    }

    //handle delete action
    const deleteAssociation = async (association) => {
        await api.deleteUserAssociation(association.id)
        revalidator.revalidate()
    }

    //submit form
    const handleFormSubmit = async (data) => {

        let promise

        if(editItem === null)
            promise = api.createUserAssociation(data.title, data.description)
        else
            promise =  api.updateUserAssociation(data.title, data.description, editItem.id)

        await promise.then(() => {
            //TODO success
            revalidator.revalidate()
            handleCancel()
        })
            .catch( error => {
                //TODO display error
            })
    }

    return(
        <div id={"ressource_details"} className={"profil_block"}>

            <div className={"bloc_title_info"}>
                <h2>Activités associative</h2>
                <p>Présentez vos activités associatives</p>
            </div>

            <div className={"skills_wrapper"}>

                {props.user.associations.map((item, i) => {
                    return(<ProfilDataBlock key={`education_data${i}`} title={item.title} description={item.description} editCallBack={() => handleEdit(item)} deleteCallBack={() => deleteAssociation(item)}/>)
                })}

                {!showAddForm &&
                    <div className={"text-right"}>
                        <button className={"cta full blue reverse"} onClick={() => setShowAddForm(true)}>+ Ajouter une activité</button>
                    </div>
                }

            </div>

            {showAddForm &&
                <div id={"addactivity_wrapper"}>

                    <div id={"addactivity_form"} className={"profil_block"}>
                        <FormAssociation user={props.user} onFormSubmit={handleFormSubmit} onFormCancel={handleCancel} association={editItem}/>
                    </div>

                </div>
            }

        </div>
    );
}

const RessourcesBlock = (props) => {

    const {register,formState: { errors }, handleSubmit, reset, setValue} = useForm();
    const handleFormSubmit = async (data) => {
        const api = new Api();
        await api.UpdateUser(props.user.id,data)
    }

    useEffect(() => {
        setValue("website", props.user.website??"")
        setValue("videoChannel", props.user.videoChannel??"")
    }, [])

    return(
        <div id={"resources_details"} className={"profil_block"}>
            <div className={"bloc_title_info"}>
                <h2>Resources</h2>
            </div>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className={"form_row_wrapper type3"}>
                    <label>Site internet</label>
                    <input type={"text"} name={"website"} id={"website"} placeholder={"Type here"} {...register("website")}/>
                </div>
                <div className={"form_row_wrapper type3"}>
                    <label>Chaîne vidéo</label>
                    <input type={"text"} name={"videoChannel"} id={"videoChannel"} placeholder={"Type here"} {...register("videoChannel")}/>
                </div>
                <div className={"text-right"}>
                    <button className={"cta full blue"}>Enregistrer les modifications</button>
                </div>
            </form>
        </div>
    )
}

export default ProfileMission;