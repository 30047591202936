import Api from "../helper/api";

/**
 * Return all user notifications list
 * @returns promise
 */
export const getNotifications = async () => {

    const api = new Api();

    let notificationsPromise = api.getUserNotifications();

    return {
        notifications : notificationsPromise
    };
}
