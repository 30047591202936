import React, {useContext} from "react";
import {AuthContext} from "../userAuth/AuthContext";
import {Navigate} from "react-router-dom";

const UnprotectedRoute = (props) => {

    const { state: ContextState } = useContext(AuthContext);

    if(ContextState.isLoggedIn){
        return(<Navigate to={"/dashboard"}/>)
    }
    else{
        return(props.children)
    }
}

export default UnprotectedRoute