import {RouterProvider} from "react-router-dom";
import router from "./component/route/Router";
import history from "./component/helper/history";
import {AuthContext, ContextProvider} from "./component/userAuth/AuthContext";

function App() {

  return (
      <ContextProvider>
        <AuthContext.Consumer>
            {(userData) => <RouterProvider router={router(userData)} history={history}/>}
        </AuthContext.Consumer>
      </ContextProvider>
  );
}

export default App;
