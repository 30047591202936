import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ItemLabel from "../../other/ItemLabel";
import {AuthContext} from "../../userAuth/AuthContext";
import {STEP_AGREEMENT, STEP_DONE, STEP_PENDING, STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED} from "../../other/Constant";
import {GetHelpDate, GetHelpTime} from "../../helper/other";
import AddNoteToHelp from "../../slidingContent/addNoteToHelp";
import SlidingPanel from "../_sliding_panel";

const HelpBlock = (props) => {

    const navigate = useNavigate()
    const {state} = useContext(AuthContext)
    const userIsOwner = props.user && props.user.id == state.user.id

    const [status, setStatus] = useState("")

    useEffect(() => {
        let maxLinkingStep = 0
        if(props.linkings){
            props.linkings.forEach((l) => {
                if(l.step > maxLinkingStep)
                    maxLinkingStep = l.step
            })
        }

        switch(maxLinkingStep){

            case STEP_PENDING :
                setStatus("")
                break;

            case STEP_RECIPIENT_ACCEPTED :
                setStatus("Matching en attente")
                break;

            case STEP_SENDER_ACCEPTED :
                setStatus("En discussion")
                break;

            case STEP_AGREEMENT :
                setStatus("Accord")
                break;

            case STEP_DONE :
                setStatus("Terminée")
                break;

            default :
                setStatus("")
        }

    },[])

    return(
        <div className={`help_block_item small ${userIsOwner?"own":""}`} onClick={() => {navigate(`/aides/${props.id}`)}}>

            <ItemLabel code={props.type.code} type={props.type.name}/>
            <div className={"content"}>
                <div className={"title"}>{props.title}</div>
                <div className={"description"}>
                    <span className={"type"}>{props.type.name}</span>
                    {status !== "" && <span className={"state"}>{status}</span>}
                </div>
            </div>
        </div>
    )
}

HelpBlock.defaultProps = {
    helpId: 0,
    title: "no title provided",
    description: "no description provided",
    linkings: [],
    createdOn: "1970-01-01 00:00:00",
}

export const HelpFullBlock = (props) => {

    const {state} = useContext(AuthContext)
    const userIsOwner = props.user && props.user.id == state.user.id

    const navigate = useNavigate()

    const [status, setStatus] = useState("")

    const [showSlidingPanel, setShowSlidingPanel] = useState(false);

    useEffect(() => {
        let maxLinkingStep = 0
        if(props.linkings){
            props.linkings.forEach((l) => {
                if(l.step > maxLinkingStep)
                    maxLinkingStep = l.step
            })
        }

        switch(maxLinkingStep){

            case STEP_PENDING :
                setStatus("")
                break;

            case STEP_RECIPIENT_ACCEPTED :
                setStatus("Matching en attente")
                break;

            case STEP_SENDER_ACCEPTED :
                setStatus("En discussion")
                break;

            case STEP_AGREEMENT :
                setStatus("Accord")
                break;

            case STEP_DONE :
                setStatus("Terminée")
                break;

            default :
                setStatus("")
        }

        console.log(props)

    },[])

    return(
        <>
            <div className={`help_block_item full ${userIsOwner ? "own" : ""} ${props.urgent ? "urgent" : ""}`}>

                {props.urgent && <span className={"help_urgent_label"}>Urgent</span> }

                <div className={"help_header"}>
                    <ItemLabel code={props.type.code} type={props.type.name}/>

                    <div className={"content"}>
                        <div className={"date"}>Publié : {GetHelpDate(props.createdOn)}</div>
                        <div className={"title"}>{props.title}</div>
                        <div className={"subtitle"}>{props.type.name}</div>
                    </div>
                </div>

                <div className={"description"}>
                    {props.description}
                </div>

                <div className={"help_info"}>
                    <div>
                        <h5>Juridiction</h5>
                        <span>{props.court?.name}</span>
                    </div>
                    <div>
                        <h5>Lieu</h5>
                        <span>{props.location}</span>
                    </div>
                    <div>
                        <h5>Date</h5>
                        <span>{GetHelpDate(props.date)}</span>
                    </div>
                    <div>
                        <h5>Heure</h5>
                        <span>{GetHelpTime(props.date)}</span>
                    </div>
                </div>

                <div className={"help_cta_wrapper"}>
                    {!userIsOwner &&
                        <>
                            <button className={"cta blue full"}>Proposer mon aide</button>
                            <button className={"cta blue full reverse"} onClick={() => setShowSlidingPanel(true)}>Envoyer une note</button>
                        </>
                    }

                    {userIsOwner &&
                        <>
                            <button className={"cta blue full reverse"} onClick={() => {navigate(`/aides/${props.id}`)}}>Afficher mon aide</button>
                        </>
                    }

                </div>

            </div>

            <SlidingPanel show={showSlidingPanel} handleClose={() => setShowSlidingPanel(false)}>
                <AddNoteToHelp handleClose={() => {
                    setShowSlidingPanel(false)
                }}/>
            </SlidingPanel>

    </>
    )

}

HelpFullBlock.defaultProps = {
    helpId: 0,
    title: "no title provided",
    description: "no description provided",
    linkings: [],
    createdOn: "1970-01-01 00:00:00",
}

export default HelpBlock