import {GenericEmptyItem, SingleMatchBlock, SingleProfilTalentBlock} from "../../other/GenericBlock";
import React, {useEffect, useState} from "react";

const SingleItemMessages = ({type}) => {

    const typeValue = {
        help : {
            class : 'help',
            //matchLink : `/aides/${itemId}/matchs`
        },
        switch : {
            class : 'switch',
            //matchLink : `/echanges/${itemId}/matchs`
        },
        mission : {
            class : 'mission',
            //matchLink : `/missions/${itemId}/matchs`
        }
    }

    return (
        <div id={`single_${typeValue[type].class}_messages`}>
            <h2>Dernières discussions</h2>
            <GenericEmptyItem title={"Ici vos prochains échanges"}/>
        </div>
    )
}

export default SingleItemMessages