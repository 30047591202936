import React, {useEffect, Suspense, useState} from "react";
import Api from "../../helper/api";
import {Await, useLoaderData, useOutletContext, useRevalidator} from "react-router-dom";
import {GenericEmptyItem} from "../../other/GenericBlock";
import ProfilDataBlock from "../../other/ProfilDataBlock";
import {CtaLike, CtaMessage, CtaReturn} from "../../other/Cta";
import SingleItemHeader, {OtherSingleItemHeader} from "../_singleItem/SingleItemHeader";
import SingleItemMatch from "../_singleItem/SingleItemMatch";
import SlidingPanel from "../_sliding_panel";
import SingleItemDetails from "../_singleItem/SingleItemDetails";
import {PROFIL_TYPE_CANDIDAT, PROFIL_TYPE_RECRUTEUR, STEP_AGREEMENT, STEP_PENDING, STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED, STEP_SENDER_REFUSED} from "../../other/Constant";

const SingleMatch = (props) => {

    //const [singleMatch, setSingleMatch] = useState([])
    const api = new Api()
    const revalidator = useRevalidator()

    const {match} = useLoaderData()
    const {user} = useOutletContext()

    const updateMatchStep = (match_id, step) => {
        api.updateMatch(match_id,step).then(() => {
            revalidator.revalidate()
        })
    }

    /*Accept the match*/
    const acceptMatch = (match_id) => updateMatchStep(match_id,3)

    /*Decline the match*/
    const declineMatch = (match_id) => updateMatchStep(match_id,4)

    /*Decline the match*/
    const agreementMatch = (match_id) => updateMatchStep(match_id,4)

    return(
        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
            <Await resolve={match} errorElement={<p>Error loading Data !</p>}>
                {(match) => {
                    console.log("match", match)
                    if(user.profileType === PROFIL_TYPE_RECRUTEUR)
                        return(<SingleMatchCurrent match={match} user={user} acceptMatch={(match_id) => updateMatchStep(match_id,STEP_SENDER_ACCEPTED)} declineMatch={(match_id) => updateMatchStep(match_id,STEP_SENDER_REFUSED)}/>)
                    else
                        return(<SingleMatchCandidat match={match} user={user} acceptMatch={(match_id) => updateMatchStep(match_id,STEP_RECIPIENT_ACCEPTED)} agreementMatch={(match_id) => updateMatchStep(match_id,STEP_AGREEMENT)}/>)
                }}
            </Await>
        </Suspense>
    )
}

const SingleMatchCurrent = ({match, user, acceptMatch, declineMatch}) => {

    return(
        <div id={"single_match_wrapper"}>

            <div className="single_match_block" id={"match_header"}>
                <CtaReturn/>
                {match.step === STEP_AGREEMENT && <CtaMessage itemId={match.id}/>}
                <CtaLike type={"match"} item={match} userId={user.id}/>
            </div>

            {match.step === STEP_RECIPIENT_ACCEPTED &&
                <div className="single_match_block" id={"match_details"}>
                    <h1>Match</h1>
                    <h5>Membre actif depuis 3 mois</h5>
                    <h2>Avocat spécialisé en droit fiscal</h2>

                    <div className="content">
                        <div className={"bar"}>{match.user.bar?.name}</div>
                        <div className={"experience"}>{match.user.experience?.name}</div>
                        <div className={"countries"}>
                            {match.user.countries.map((item) => <div>{item.name}</div>)}
                        </div>
                    </div>

                </div>
            }

            {match.step === STEP_SENDER_ACCEPTED &&
                <div className="single_match_block" id={"match_details"}>
                    <h2>Profil</h2>

                    <div className="content">
                        <div className={"name picto name"}>{match.user.firstname} {match.user.lastname}</div>
                        <div className={"mail picto mail"}>{match.user.email}</div>
                        <div className={"phone picto phone"}>{match.user.phone}</div>
                        <div className={"bar picto bar"}>{match.user.bar?.name}</div>
                        <div className={"experience picto experience"}>{match.user.experience?.name}</div>
                        <div className={"countries picto language"}>
                            {match.user.countries.map((item) => <div>{item.name}</div>)}
                        </div>
                    </div>
                </div>
            }

            <div className="single_match_block" id={"match_presentation"}>
                <h2>Présentation</h2>
                <p>{match.user.description}</p>
            </div>

            <div className="single_match_block" id={"match_skill"}>
                <h2>Compétences</h2>
                {match.user.skills.map((item, i) => <ProfilDataBlock title={item.area.name} experience={item.experience.name} international={item.countries} description={item.description} subareas={item.subareas}/>)}
            </div>

            <div className="single_match_block" id={"match_specialization"}>
                <h2>Spécialisations</h2>
                {match.user.specializations.map((item, i) => <ProfilDataBlock key={`specialization_data${i}`} title={item.specialization.name} experience={item.experience.name} description={item.description}/>)}
            </div>

            <div className="single_match_block" id={"match_industry"}>
                <h2>Secteurs d'activité</h2>
                {match.user.industries.map((item, i) => <ProfilDataBlock key={`secteur_data${i}`} title={item.industry.name} experience={item.experience ? item.experience.name : null} international={item.countries}
                                                                         description={item.description}/>)}
            </div>

            <div className="single_match_block" id={"match_job"}>
                <h2>Parcours professionnel</h2>
                {match.user.jobs.map((item, i) => <ProfilDataBlock key={`job_data${i}`} title={item.title} description={item.description}/>)}
            </div>

            <div className="single_match_block" id={"match_formation"}>
                <h2>Formation et certifications</h2>
                {match.user.educations.map((item, i) => <ProfilDataBlock key={`education_data${i}`} title={item.title} description={item.description}/>)}
            </div>


            <div className="single_match_block" id={"cta_wrapper"}>
                {match.step === STEP_RECIPIENT_ACCEPTED &&
                    <>
                        <button className={"cta blue full"} onClick={() => acceptMatch(match.id)}>Je suis intéressé et souhaite échanger</button>
                        <button className={"cta blue reverse full"} onClick={() => declineMatch(match.id)}>Non merci</button>
                    </>
                }
            </div>

        </div>
    )
}

const SingleMatchCandidat = ({match, user,acceptMatch, agreementMatch}) => {

    //filter
    const [showSlidingPanelDetails, setShowSlidingPanelDetails] = useState(false);

    console.log(match)

    return(
        <div className={"single_item_wrapper"} id={"single_help_wrapper"}>


            <div className={"other_single_item_header"}>

                <div className={"picture"}/>

                <div className={"content"}>
                    <div className={"title"}>{match.mission.title}</div>
                    {match.mission.bar &&
                        <div className={"other_single_item_data"}>
                            <label>Barreau actuel</label>
                            <div>{match.mission.bar.name}</div>
                        </div>
                    }
                    {match.mission.experience &&
                        <div className={"other_single_item_data"}>
                            <label>Expérience</label>
                            <div>{match.mission.experience.name}</div>
                        </div>
                    }
                    {match.mission.estimatedBudget &&
                        <div className={"other_single_item_data"}>
                            <label>Budget prévisionel</label>
                            <div>{match.mission.estimatedBudget} €</div>
                        </div>
                    }
                    {match.mission.estimatedDuration &&
                        <div className={"other_single_item_data"}>
                            <label>Durée estimée</label>
                            <div>{match.mission.estimatedDuration.name}</div>
                        </div>
                    }
                    {match.mission.location &&
                        <div className={"other_single_item_data"}>
                            <label>Type</label>
                            <div>{match.mission.location}</div>
                        </div>
                    }
                    {match.mission.type &&
                        <div className={"other_single_item_data"}>
                            <label>Type</label>
                            <div>{match.mission.type.name}</div>
                        </div>
                    }
                    {match.mission.collaborationType &&
                        <div className={"other_single_item_data"}>
                            <label>Type de collaboration</label>
                            <div>{match.mission.collaborationType.name}</div>
                        </div>
                    }
                    {match.mission.description &&
                        <div className="single_match_block" id={"match_presentation"}>
                            <h2>Présentation</h2>
                            <p>{match.mission.description}</p>
                        </div>
                    }
                </div>

            </div>

            <div id={"single_help_cta"} className={"single_item_cta_wrapper"}>
                {match.step === STEP_PENDING && <button className={"cta blue full"} onClick={() => acceptMatch(match.id)}>Candidater</button>}
                {match.step === STEP_RECIPIENT_ACCEPTED && <button className={"cta transparent full disabled"}>Vous avez candidaté cette mission</button>}
                {match.step === STEP_SENDER_ACCEPTED && <button className={"cta blue full"} onClick={() => agreementMatch(match.id)}>Passer à l'accord</button>}
                {/*match.step === STEP_AGREEMENT && <button className={"cta blue full"}>coucou</button>*/}
            </div>

            <SlidingPanel show={showSlidingPanelDetails} handleClose={() => setShowSlidingPanelDetails(false)}>
                <SingleItemDetails singleItem={match}/>
            </SlidingPanel>

        </div>
    )
}

export default SingleMatch