import React, {useCallback} from "react";
import Autocomplete from "react-google-autocomplete";

const AddressAutoComplete = ({name, id, setValue, defaultValue, register, errors, required}) => {


    const handleSelectAddress = useCallback( (address) => {
        setValue({
            address : address.formatted_address,
            latitude : address.geometry.location.lat(),
            longitude : address.geometry.location.lng()
        })
    }, [name, setValue])


    return(
        <>
            <input type={"hidden"} {...register(name, {required: required, message: "Champs obligatoire"})} name={name} id={id} defaultValue={defaultValue ?? ""}/>

            <Autocomplete
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                onPlaceSelected={(place) => handleSelectAddress(place)}
                options={{
                    types: [] // all type
                }}
                defaultValue={defaultValue ?? ""}
                type={"text"}
            />

            <div className={"error"}>{errors && errors.location && errors.location.message}</div>
        </>
    )
}

export default AddressAutoComplete

AddressAutoComplete.defaultProps = {
    name : "noname",
    id : "noid",
    defaultValue : "",
    required : false,
    setValue : (address) => console.log("setValue is not set : "+address),
    register : (name) =>  console.log("register is not set : "+name),
    errors : false,
}