import axios from "axios";
import history from "./history";

export default class Api {

    constructor() {
        this.api_url = process.env.REACT_APP_API_URL;
        this.api_token = "";
    }

    init = () => {

        if(this.api_token === ""){
            const userInfo = JSON.parse(localStorage.getItem("user"))

            /*if(userInfo === null)
                this.RemoveAuth()
            else*/
            if(userInfo !== null)
                this.api_token = userInfo.token
        }

        let headers = {
            Accept: "application/json",
        };

        if (this.api_token) {
            headers.Authorization = `Bearer ${this.api_token}`;
        }

        const client = axios.create({
            baseURL: this.api_url,
            timeout: 60000,
            headers: headers,
            'Content-Type': 'multipart/form-data'
        });
        const current_token = this.api_token

        //request intercepter
        client.interceptors.request.use(null,function (error){
            this.ErrorLog(error)
        })

        //response interceptor
        client.interceptors.response.use(null, function (error) {

            if(error.response){
                //expired token
                switch(error.response.status){
                    //Expired Token
                    case 401 :
                        //disconnect user only if token exist
                        if(current_token !== "")
                        {
                            localStorage.clear()
                            //history.push("/connexion")
                            window.location.href="/connexion"
                        }
                        break;
                }
            }

            // Do something with response error
            return Promise.reject(error);
        });

        return client;
    };

    ErrorLog = (error) => {
        console.log(error)
    }

    RemoveAuth = () => {
        console.log("coucou")
        localStorage.clear()
        history.push("/connexion")
    }

    sendRequest = (requestType, endPoint, params={}, config = {}) => {

        let data

        switch(requestType){
            case "get" :
                //format params if exist
                let formattedParams = []

                if(Object.keys(params).length > 0){
                    for (const [key, p] of Object.entries(params)) {

                        if( (Array.isArray(p) && p.length > 0) || ( !Array.isArray(p) && p !== undefined && p !== "" && p !== null))
                            formattedParams = [...formattedParams,this.formatGetParams(p, key)]
                    }

                    if(formattedParams.length > 0) {
                        endPoint = endPoint + "?" + formattedParams.join('&')
                    }
                }

                data = this.init().get(endPoint, {...config})
            break;

            case "post" :
                data = this.init().post(endPoint, params instanceof FormData ? params : {...params}, {...config})
            break;

            case "patch" :
                data = this.init().patch(endPoint, params instanceof FormData ? params : {...params}, {...config})
            break;

            case "delete" :
                data = this.init().delete(endPoint, {...config})
                break;
        }

        if(data === undefined)
            return []
        else
            return data.then((data) => data.data)
    }

    /**
     * Format data to match api routes
     * @param keys [{value, route}]
     * @param params
     */
    formatApiRoute = (keys, params) => {

        keys.forEach((key) => {

            let tempData

            //if params key exist
            if(params[key.value]){

                //params is an array
                if(params[key.value] instanceof Array){
                    tempData = []
                    //convert param object to api route
                    params[key.value].forEach((item) => {
                        tempData = [...tempData, key.route+item.id]
                    })
                }
                else if(typeof params[key.value] == "string" || typeof params[key.value] == 'number'){
                    tempData = key.route+params[key.value]
                }else{
                    console.log("nothing to do")
                    console.log(typeof params[key.value]);
                }

                params[key.value] = tempData
            }
        })

        return params
    }

    /**
     * Format simple or array param for get request
     * @param params
     * @param name
     * @returns {string}
     */
    formatGetParams = (params, name) => {
        let formattedParams

        if(Array.isArray(params))
            formattedParams = encodeURI(name+"[]="+params.join('&'+name+'[]='))
        else
            formattedParams = name+"="+params

        return formattedParams
    }

    /**
     * get JWT
     * @param params (email, password)
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getUserToken = async (params) => {
        return this.sendRequest("post","/auth", params);
    }

    /**
     * Get user
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getUser = async () => {
        //await new Promise(r => setTimeout(r, 5000));
        return this.sendRequest("get","/api/account");
    }

    /**
     * Create a user
     * @param params
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    createUser = async ( params ) => {
        return await this.sendRequest("post","/api/users", params);
    }

    /**
     * Update user
     * @param {*} params 
     * @returns 
     */
    UpdateUser = async (id,params) => {

        //convert object to api route
        params = this.formatApiRoute([
            {value : "bar", route : "api/bars/"},
            {value : "countries", route : "api/countries/"},
            {value : "experience", route : "api/experiences/"},
            {value : "collaborationType", route : "api/collaboration_types/"},
            {value : "available", route : "api/availabilities/"},
        ], params)

        return this.sendRequest("patch","/api/users/"+id,{...params})
    }

    /**
     * Set user avatar picture
     * @param file
     * @returns {Promise<axios.AxiosResponse<any> | void>}
     */
    setUserAvatar = async (file) => {

        let formData = new FormData();
        formData.append("imageFile", file);

        return this.sendRequest("post", "/api/picture", formData);
    }

    /**
     * Set user profile type
     * @param type
     * @returns {Promise<axios.AxiosResponse<any> | void>}
     */
    setUserProfilType = async (id, type) => {
        return this.UpdateUser(id, { profileType : type })
    }

    /**
     * Set user professionnal card
     * @param file
     * @returns {Promise<axios.AxiosResponse<any> | void>}
     */
    setUserProfessionnalCard = async (file) => {

        let formData = new FormData();
        formData.append("professionalCertificate", file);

        return this.sendRequest("post", "/api/professional_certificate", formData);
    }

    /**
     * Reset user password
     * @param mail
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    userLostPassword = (mail) => {
        return this.init().post("/forgot_password/", {email : mail}).catch(this.ErrorLog);
    }

    /**
     * Create a user specialization ressource
     * @param {*} description 
     * @param {*} specialization
     * @param {*} experience
     * @returns 
     */
    createUserSpesialization = (description, specialization, experience) => {

        const params = this.formatApiRoute([
            {value : "specialization", route : "api/specializations/"},
            {value : "experience", route : "api/experiences/"}
        ], {
            description : description,
            specialization : specialization,
            experience : experience
        })

        return this.sendRequest("post","/api/user_specializations",{...params});
    }

    /**
     * update a user specialization ressource
     * @param {*} description
     * @param {*} specialization
     * @param {*} experience
     * @param {*} id
     * @returns
     */
    updateUserSpesialization = (description, specialization, experience, id) => {

        const params = this.formatApiRoute([
            {value : "specialization", route : "api/specializations/"},
            {value : "experience", route : "api/experiences/"}
        ], {
            description : description,
            specialization : specialization,
            experience : experience
        })

        return this.sendRequest("patch","/api/user_specializations/"+id,{...params});
    }

    /**
     * delete a user specialization ressource
     * @param {*} id
     * @returns
     */
    deleteUserSpesialization = (id) => {
        return this.sendRequest("delete","/api/user_specializations/"+id);
    }

    /**
     * Create a user skill ressource
     * @param {*} description
     * @param {*} skill
     * @param {*} experience
     * @param {*} countries
     * @returns
     */
    createUserSkill = async (description, area, experience, countries, subareas) => {

        const params = this.formatApiRoute([
            {value : "countries", route : "api/countries/"},
            {value : "experience", route : "api/experiences/"},
            {value : "area", route : "api/areas/"},
            {value : "subareas", route : "api/subareas/"},
        ],
        {
            description : description,
            area : area,
            experience : experience,
            countries : countries,
            subareas : subareas
        })


        return this.sendRequest("post","/api/user_skills",{...params});
    }

    /**
     * Update a user skill ressource
     * @param {*} description
     * @param {*} skill
     * @param {*} experience
     * @param {*} countries
     * @param {*} id
     * @returns
     */
    updateUserSkill = async (description, skill, experience, countries, subareas, id) => {

        const params = this.formatApiRoute([
                {value : "countries", route : "api/countries/"},
                {value : "experience", route : "api/experiences/"},
                {value : "area", route : "api/areas/"},
                {value : "subareas", route : "api/subareas/"},
            ],
            {
                description : description,
                area : skill,
                experience : experience,
                countries : countries,
                subareas : subareas
            })


        return this.sendRequest("patch","/api/user_skills/"+id,{...params});
    }

    /**
     * Delete a user skill ressource
     * @param {*} id
     * @returns
     */
    deleteUserSkill = async (id) => {
        return this.sendRequest("delete","/api/user_skills/"+id);
    }

    /**
     * Create a user industry ressource
     * @param {*} description
     * @param {*} industry
     * @param {*} experience
     * @param {*} countries
     * @returns
     */
    createUserIndustry = async (description, industry, experience, countries) => {

        const params = this.formatApiRoute([
                {value : "countries", route : "api/countries/"},
                {value : "experience", route : "api/experiences/"},
                {value : "industry", route : "api/industries/"},
            ],
            {
                description : description,
                industry : industry,
                experience : experience,
                countries : countries,
            })

        return this.sendRequest("post","/api/user_industries",{...params});
    }

    /**
     * Update a user industry ressource
     * @param {*} description
     * @param {*} industry
     * @param {*} experience
     * @param {*} countries
     * @returns
     */
    updateUserIndustry = async (description, industry, experience, countries, id) => {

        const params = this.formatApiRoute([
                {value : "countries", route : "api/countries/"},
                {value : "experience", route : "api/experiences/"},
                {value : "industry", route : "api/industries/"},
            ],
            {
                description : description,
                industry : industry,
                experience : experience,
                countries : countries,
            })

        return this.sendRequest("patch","/api/user_industries/"+id,{...params});
    }

    /**
     * Delete a user industry ressource
     * @param {*} id
     * @returns
     */
    deleteUserIndustry = async (id) => {
        return this.sendRequest("delete","/api/user_industries/"+id);
    }

    /**
     * Create a user job ressource
     * @param {*} title
     * @param {*} description
     * @returns
     */
    createUserJob = async (title, description) => {
        return this.sendRequest("post","/api/jobs",{
            title : title,
            description : description,
        });
    }

    /**
     * Update a user job ressource
     * @param {*} title
     * @param {*} description
     * @param {*} id
     * @returns
     */
    updateUserJob = async (title, description, id) => {
        return this.sendRequest("patch","/api/jobs/"+id,{
            title : title,
            description : description,
        });
    }

    /**
     * Delete a user job ressource
     * @param {*} id
     * @returns
     */
    deleteUserJob = async (id) => {
        return this.sendRequest("delete","/api/jobs/"+id);
    }

    /**
     * Create a user education ressource
     * @param {*} title
     * @param {*} description
     * @returns
     */
    createUserEducation = async (title,description) => {

        return this.sendRequest("post","/api/education",{
            title : title,
            description : description
        });
    }

    /**
     * Update a user education ressource
     * @param {*} title
     * @param {*} description
     * @param {*} id
     * @returns
     */
    updateUserEducation = async (title, description, id) => {

        return this.sendRequest("patch","/api/education/"+id,{
            title : title,
            description : description
        });
    }

    /**
     * Delete a user education ressource
     * @param {*} id
     * @returns
     */
    deleteUserEducation = async (id) => {

        return this.sendRequest("delete","/api/education/"+id);
    }

    /**
     * Create a user association ressource
     * @param {*} title
     * @param {*} description
     * @returns
     */
    createUserAssociation = async (title,description) => {

        return this.sendRequest("post","/api/associations",{
            title : title,
            description : description,
        });
    }

    /**
     * Update a user association ressource
     * @param {*} title
     * @param {*} description
     * @param {*} id
     * @returns
     */
    updateUserAssociation = async (title, description, id) => {

        return this.sendRequest("patch","/api/associations/"+id,{
            title : title,
            description : description,
        });
    }

    /**
     * Delete a user association ressource
     * @param {*} id
     * @returns
     */
    deleteUserAssociation = async (id) => {
        return this.sendRequest("delete","/api/associations/"+id);
    }

    /**
     * Create a mission
     * @param mission
     * @param industries
     * @param skills
     * @param specializations
     * @returns {Promise<[]|any>}
     */
    createMission = async (mission, industries, skills, specializations) => {

        mission = this.formatApiRoute([
                {value : "collaborationType", route : "api/collaboration_types/"},
                {value : "estimatedDuration", route : "api/durations/"},
                {value : "experience", route : "api/experiences/"},
                {value : "type", route : "api/mission_types/"},
                {value : "bar", route : "api/bars/"},
            ],mission)

        industries.forEach((item, key)=> {

            item = this.formatApiRoute([
                {value : "countries", route : "api/countries/"},
                {value : "experience", route : "api/experiences/"},
                {value : "industry", route : "api/industries/"},
            ],item)

            industries[key] = item
        })

        skills.forEach((item, key)=> {
            item = this.formatApiRoute([
                {value : "area", route : "api/areas/"},
                {value : "countries", route : "api/countries/"},
                {value : "experience", route : "api/experiences/"},
                {value : "subareas", route : "api/subareas/"},
            ],item)

            skills[key] = item
        })

        specializations.forEach((item, key)=> {
            item = this.formatApiRoute([
                {value : "countries", route : "api/countries/"},
                {value : "experience", route : "api/experiences/"},
                {value : "specialization", route : "api/specializations/"},
            ],item)

            specializations[key] = item
        })

        return this.sendRequest("post","/api/missions",{...mission, skills: skills, industries: industries, specializations: specializations});
    }

    /**
     * Return a collection of skills
     * @returns 
     */
    getSkillsList = async () => {
        return this.sendRequest("get","/api/skills");
    }

    /**
     * Return a collection of specializations
     * @returns 
     */
    getSpesializationsList = async () => {
        return this.init().get("/api/specializations").catch(this.ErrorLog);
    }

    /**
     * Return a collection of area / skills
     * @returns 
     */
    getAreasList = async () => {
        return this.sendRequest("get","/api/areas");
    }

    /**
     * Return a collection of subarea / subskills
     * @returns 
     */
    getSubAreasList = async () => {
        return this.sendRequest("get","/api/subareas");
    }

    /**
     * Return a collection of specializations
     * @returns
     */
    getSpecializationsList = async () => {
        return this.sendRequest("get","/api/specializations");
    }

    /**
     * Return a collection of industries
     * @returns
     */
    getIndustriesList = async () => {
        return this.sendRequest("get","/api/industries");
    }

    /**
     * Return a collection of Experience Years
     * @returns
     */
    getExperienceYearList = async () => {
        return await this.sendRequest("get","/api/experiences");
    }

    /**
     * Return a collection of mission type
     * @returns
     */
    getMissionTypesList = async () => {
        return this.sendRequest("get","/api/mission_types");
    }

    /**
     * Return a collection of mission Collaborations type
     * @returns
     */
    getMissionCollaborationTypesList = async () => {
        return this.sendRequest("get","/api/collaboration_types");
    }

    /**
     * Return a collection of Bar
     * @returns
     */
    getBarList = async () => {
        return this.sendRequest("get","/api/bars");
    }

    /**
     * Return a collection of Language
     * @returns
     */
    getLangList = async () => {
        return [
            {id : 0, name : "Français"},
            {id : 1, name : "Anglais"},
            {id : 2, name : "Allemand"},
        ];
    }

    /**
     * Return a collection of country
     * @returns
     */
    getCountryList = async () => {
        return await this.sendRequest("get","/api/countries");
    }

    /**
     * Return a collection of duration
     * @returns
     */
    getDurationList = async () => {
        return this.sendRequest("get","/api/durations");
    }

    /**
     * Return a collection of Mission for current user
     * @returns
     */
    getUserMissions = async (steps=null, published= null, closed= null) => {
        return this.sendRequest("get","/api/missions",{
            step : steps,
            published : published,
            closed : closed
        });
    }

    /**
     * Return missions linking
     * @param missions
     * @param steps
     */
    getMissionLinkings = async (missions = null, steps = null) => {
        return this.sendRequest("get","/api/linkings",{mission : missions??[], step : steps??[]});
    }

    /**
     * Return help linkings
     * @returns {Promise<[]|*>}
     */
    getHelpLinkings = async (helps = null, steps = null, mine = null) => {
        return this.sendRequest("get","/api/help_linkings",{
            help : helps,
            step : steps,
            mine : mine
        });
    }

    /**
     * Return switch linking
     * @param help
     * @param steps
     */
    getSwitchLinkings = async (switchDuty = null, steps = null) => {
        return this.sendRequest("get","/api/switch_duty_linkings",{switchDuty : switchDuty, step : steps});
    }

    /**
     * Return a specific Mission
     * @returns
     */
    getMission = async (missionId) => {
        //const encoded_steps = encodeURIComponent(steps);
        return this.sendRequest("get","/api/missions/"+missionId);
    }

    /**
     * Return a specific Help
     * @returns
     */
    getHelp = async (helpId) => {
        //const encoded_steps = encodeURIComponent(steps);
        return this.sendRequest("get","/api/helps/"+helpId);
    }

    /**
     * Return a specific Switch
     * @returns
     */
    getSwitch = async (switchId) => {
        return this.sendRequest("get","/api/switch_duties/"+switchId);
    }

    /**
     * Return all job ressousrce pour sign up
     * @returns
     */
    getJob = async () => {
        return [
            {name: 'Avocat', id: 0},
            {name: 'Entreprises (services juridiques, RH...)', id: 1},
            {name: 'Experts (professeurs, docteurs, consultants, DPO…)', id: 2}
        ];
    }

    /**
     * return a list of availability ressource
     * @returns
     */
    getAvailability = async () => {
        return this.sendRequest("get","/api/availabilities");
    }

    /**
     * Retrieve a mission match ressource
     * @type {{}}
     */
    getMatch = async (matchId) => {
        return this.sendRequest("get","/api/linkings/"+matchId);
    }

    /**
     * Retrieve a Help match ressource
     * @type {{}}
     */
    getHelpMatch = async (matchId) => {
        return this.sendRequest("get","/api/help_linkings/"+matchId);
    }

    /**
     * Retrieve a Switch match ressource
     * @type {{}}
     */
    getSwitchMatch = async (matchId) => {
        return this.sendRequest("get","/api/switch_duty_linkings/"+matchId);
    }

    /**
     * validate phone number
     * @type
     */
    verifyPhoneNumber = async (code) => {
        return this.sendRequest("post","/api/verify",{
            "verificationCode": code
        });
    }

    /**
     * Get Radius List value
     * @type
     */
    getRadiusList = async () => {
        //return this.sendRequest("post","/api/verify",);
        return [
            {id : 1, name : "France entière"},
            {id : 2, name : "Autre"}
        ]
    }

    /**
     * Return all user help
     * @returns {Promise<[]|*>}
     */
    getHelps = async (published = null, closed = null, urgent = null, users = null, steps = null) => {
        return this.sendRequest("get","/api/helps",{
            published : published,
            closed : closed,
            urgent : urgent,
            user : users,
            step : steps,
        });
    }

    /**
     * Return all user Switch Duties
     * @returns {Promise<[]|*>}
     */
    getUserSwitchDuties = async (published = null, closed = null, urgent = null, users = null, steps = null) => {
        return this.sendRequest("get","/api/switch_duties",{
            published : published,
            closed : closed,
            urgent : urgent,
            user : users,
            step : steps,
        });
    }

    /**
     * Return all the user notifications
     * @returns {Promise<[]|*>}
     */
    getUserNotifications = async () => {
        return this.sendRequest("get","/api/notifications");
    }

    /**
     * set Match as favorite
     */
    setMatchBookmark = (matchId, userId) => {

        const params = this.formatApiRoute([
            {value : "linking", route : "api/linkings/"},
            {value : "user", route : "api/users/"}
        ], {
            linking : matchId,
            user : userId
        })

        return this.sendRequest("post","/api/linking_bookmarks",{
            "linking" : params.linking,
            "user" : params.user
        });

    }

    /**
     * Delete a match bookmark
     * @param matchId
     * @returns {[]|*}
     */
    deleteMatchBookmark = (matchId) => {
        return this.sendRequest("delete","/api/linking_bookmarks/"+matchId);
    }

    /**
     * Patch mission match step
     * @param matchId
     * @param step
     * @returns {[]|*}
     */
    updateMatch = (matchId, step) => {
        return this.sendRequest("patch","/api/linkings/"+matchId,{
            step : step
        });
    }

    /**
     * Return a collection of help type
     * @returns
     */
    getHelpTypesList = async () => {
        return this.sendRequest("get","/api/help_types");
    }

    /**
     * Return a collection of court
     * @returns
     */
    getCourtsList = async () => {
        return this.sendRequest("get","/api/courts");
    }


    /**
     * Create Help request
     * @param title
     * @param description
     * @param type
     * @param date
     * @param urgent
     * @param court
     * @param location
     * @param bar
     * @returns {Promise<[]|*>}
     */
    createHelp = async (title, description, type, date, urgent, court, location, bar) => {

        const params = this.formatApiRoute([
                {value : "type", route : "api/help_types/"},
                {value : "court", route : "api/courts/"},
                {value : "bar", route : "api/bars/"},
            ],
            {
                type : type,
                court : court,
                bar : bar
            })

        return this.sendRequest("post","/api/helps",{
            title : title,
            description : description,
            published : true,
            type : params.type,
            date : date,
            closed : false,
            linkingOpened : true,
            urgent : urgent,
            court : params.court,
            latitude : 0,
            longitude : 0,
            location : location,
            bar : params.bar
        });

    }

    /**
     * Patch a mission
     * @param missionId
     * @param params
     * @returns {[]|*}
     */
    updateMission = (missionId, params) => {
        return this.sendRequest("patch","/api/missions/"+missionId,params);
    }

    /**
     * Delete a mission
     * @param missionId
     * @returns {[]|*}
     */
    deleteMission = (missionId) => {
        return this.sendRequest("delete","/api/missions/"+missionId);
    }

    /**
     * Check if user using oath exist
     * @param appleId
     * @param googleId
     * @param linkedinId
     */
    checkIfExist = (appleId = "", googleId = "", linkedinId = "") => {
        return this.sendRequest("post","/api/check-exist",{
            "googleId": googleId,
            "linkedinId": linkedinId,
            "appleId": appleId
        });
    }
}