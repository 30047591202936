import { useNavigate } from 'react-router-dom';
import Api from "../helper/api";
import React, {useState, useEffect} from "react";
import {useGoogleLogin} from '@react-oauth/google';
import axios from "axios";

export const CtaReturn = () => {

    const navigate = useNavigate();

    return(
        <button className={"cta return"} onClick={() => navigate(-1)}/>
    )
}

export const CtaLike = ({item, userId, isActive=false}) => {

    const api = new Api()
    const [bookMarkId, setBookMarkId] = useState(item.bookmarks ? item.bookmarks.at(0).id : null)

    const handleLikeClick = async () => {

        if(bookMarkId === null) {
            api.setMatchBookmark(item.id, userId).then((data) => setBookMarkId(data.id))
        }
        else {
            api.deleteMatchBookmark(bookMarkId)
            setBookMarkId(null)
        }
    }

    return(
        <button className={`cta like ${bookMarkId!==null?"active":""}`} onClick={handleLikeClick}/>
    )
}

export const CtaMessage = ({itemId, userId}) => {

    const handleMessageClick = () => {
        console.log("cta message ", itemId, userId)
    }

    return(
        <button className={"cta message"} onClick={handleMessageClick}/>
    )
}

export const CtaGoogleLogin = () => {

    const [user, setUser] = useState([])
    const navigate = useNavigate()
    const api = new Api()

    const handleLoginGoogle = useGoogleLogin({
        onSuccess: tokenResponse => setUser(tokenResponse)
    });

    useEffect(
        () => {
            if (user.access_token) {
                axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {

                    const data = {
                        email: res.data?.email,
                        googleId: res.data?.id,
                        password: res.data?.id,
                        locale: res.data.locale.substring(0, 2),
                        lastname: res.data?.family_name,
                        firstname: res.data?.given_name
                    }

                    api.checkIfExist("",data.googleId,"")
                        .then(()=>{
                            //user exist -> connect
                        })
                        .catch(() => {
                            //user don't exist -> create account
                            navigate(`/inscription_sso`, {state : {...data}})
                        })

                })
                .catch((err) => console.log(err));
            }
        },
        [ user ]
    );

    return (
        <button className={"cta social_login google"} onClick={() => handleLoginGoogle()}><span>Continuer avec Google</span></button>
    )
}