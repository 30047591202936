import React, {useEffect, useState, Suspense} from "react";
import {Await, useLoaderData, useOutletContext} from "react-router-dom";

import FilterButtonBar from "../../other/FilterButtonBar";

import SlidingPanel from "../_sliding_panel";
import {GenericEmptyItem} from "../../other/GenericBlock";
import {useSetState} from "react-use";
import SwitchBlock from "./_SwitchBlock";
import AddPermanenceRequest from "../../slidingContent/AddPermanenceRequest";
import HelpBlock from "../help/_HelpBlock";

const Switchs = () => {

    const [filterValue, setFilterValue] = useState(0);

    const {switchs, urgentSwitchs, userSwitchs, userApplications} = useLoaderData()
    const {user} = useOutletContext();

    const [switchsState, setSwitchState] = useSetState()

    useEffect(() => {

        switchs.then((switchs) => setSwitchState({switchs}))
        urgentSwitchs.then((urgent_switchs) => setSwitchState({urgent_switchs}))
        userSwitchs.then((user_switchs) => setSwitchState({user_switchs}))
        userApplications.then((user_applications) => setSwitchState({user_applications}))

    }, []);

    //sliding panel
    const [showSlidingPanel, setShowSlidingPanel] = useState(false);

    return(
        <div id={"helps_wrapper"}>

            <FilterButtonBar filters={[
                {value: "switch", label: `Demandes`, count: switchsState.switchs?.length},
                {value: "user_switch", label: `Mes demandes`, count: switchsState.user_switchs?.length},
                {value: "user_application", label: `Candidatures`, count: switchsState.user_applications?.length},
            ]} setFilterValue={setFilterValue}/>

            <div className={"text-center"}>
                <button className={"cta full blue"} onClick={() => setShowSlidingPanel(true)}>Changer une permanence</button>
            </div>

            {filterValue === 0 &&
                <div id={"switch_current"} className={"filter_container"}>
                    <div className={"switch_block_wrapper"}>

                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={switchs}>
                                {(switchs) => {
                                    if (switchs.length > 0)
                                        return (switchs.map((s, key) => <SwitchBlock {...s} key={`switch_${s.id}`}/>))
                                    else
                                        return (<GenericEmptyItem title={"Aucune demande pour le moment"}/>)
                                }}
                            </Await>
                        </Suspense>

                    </div>
                </div>
            }

            {filterValue === 1 &&
                <div id={"switch_user_switch"} className={"filter_container"}>
                    <div className={"switch_block_wrapper"}>

                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={userSwitchs}>
                                {(switchs) => {
                                    if (switchs.length > 0)
                                        return (switchs.map((s, key) => <SwitchBlock {...s} key={`switch_${s.id}`}/>))
                                    else
                                        return (<GenericEmptyItem title={"Aucune demande pour le moment"}/>)
                                }}
                            </Await>
                        </Suspense>

                    </div>
                </div>
            }

            {filterValue === 2 &&
                <div id={"switch_application"} className={"filter_container"}>
                    <div className={"switch_block_wrapper"}>

                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={userApplications}>
                                {(appplications) => {
                                    if (appplications.length > 0)
                                        return (appplications.map((appplication, key) => <SwitchBlock {...appplication.switch} key={`switch_${appplication.switch.id}`}/>))
                                    else
                                        return (<GenericEmptyItem title={"Aucune candidature pour le moment"}/>)
                                }}
                            </Await>
                        </Suspense>

                    </div>
                </div>
            }

            <SlidingPanel show={showSlidingPanel} handleClose={() => setShowSlidingPanel(false)}>
                <AddPermanenceRequest user={user} handleClose={() => setShowSlidingPanel(false)}/>
            </SlidingPanel>

        </div>
    )
}

export default Switchs