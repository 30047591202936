//PROFIL TYPE
export const PROFIL_TYPE_RECRUTEUR = 0
export const PROFIL_TYPE_CANDIDAT = 1

//LINKINGS STEP
export const STEP_PENDING = 0;             // creation du match grace a l’algo
export const STEP_RECIPIENT_ACCEPTED = 1;  // le candidat accepte le match (interêt)
export const STEP_RECIPIENT_REFUSED = 2;   // le candidat refuse le match
export const STEP_SENDER_ACCEPTED = 3;     // le recruteur accepte le match
export const STEP_SENDER_REFUSED = 4;      // le recruteur refuse le match
export const STEP_AGREEMENT = 5;           // Accord
export const STEP_DONE = 6;                // Match terminé