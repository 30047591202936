import React, {useContext, useEffect} from "react";
import {AuthContext} from "../userAuth/AuthContext";
import {Navigate, useLocation} from "react-router-dom";

const ProtectedRoute = (props) => {

    const { state: ContextState } = useContext(AuthContext);
    const route = useLocation();

    useEffect(() => {
        if(!ContextState.isLoggedIn) {
            console.log("save private route")
            localStorage.setItem("privateRoute",route.pathname);
        }
    },[]);

    if(!ContextState.isLoggedIn){
        return(<Navigate to={"/connexion"}/>)
    }
    else{
        return(props.children)
    }
}

export default ProtectedRoute