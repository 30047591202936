//pattern
export let numberAndAlphaPattern_nospace = /^[0-9a-zA-ZÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ\-',.]+$/;
export let numberAndAlphaPattern = /^[0-9a-zA-ZÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ \-',.]+$/;
export let numberPattern = /^[0-9 ]+$/;
export let alphaPattern = /^[a-zA-ZÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ \-',.]+$/;
export let emailPattern = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

/**
 * return api object from array of object by id
 * @param id
 * @param array
 * @returns {boolean|*}
 */
export const getItemFromArrayById = (id, array) => {

    if(!Array.isArray(array)){
        console.log("array Is not a valid Array")
        return false;
    }

    if(array.length === 0){
        console.log("array is empty")
        return false;
    }

    const result = array.filter((item) => parseInt(item.id) === parseInt(id))

    if(result.count === 0){
        console.log("result is empty")
        return false;
    }

    return result[0]
}
/**
 * Convert an occurence or an array of api object to an array of object id
 * @param items
 */
export const getIdFromApiObject = (items) => {

    let itemsId

    //Array of object
    if(Array.isArray(items)){

        itemsId = []

        if(items.length === 0){
            console.log("Array is empty")
            return false;
        }

        items.forEach((item) => {
            if(item.id)
                itemsId = [...itemsId, item.id]
        })

    }else{
        //simple object
        itemsId = items.id ?? false
    }

    return itemsId
}