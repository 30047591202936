import React from "react";

const SlidingPanel = ({show, handleClose, children}) => {

    return(
        <div id={"sliding_panel"} className={ show?'show':'' }>
            <a href={"#0"} id={"sliding_panel_close"} onClick={handleClose}></a>
            <div id={"sliding_panel_body"}>
                {children}
            </div>
        </div>
    )
}

SlidingPanel.defaultProps = {
    show : false,
    handleClose : () => {console.log("handleClose props is missing")}
}

export default SlidingPanel