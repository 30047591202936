import React, {Suspense, useEffect, useState} from "react";

import {Await, useLoaderData, useNavigate, useOutletContext} from "react-router-dom";

import {Link} from "react-router-dom";
import {GenericEmptyItem, SingleMatchBlock} from "../other/GenericBlock";
import {useSetState} from "react-use";
import {sortMissionByStatus} from "../helper/missionHelper";
import * as Constant from "../other/Constant";
import {PROFIL_TYPE_RECRUTEUR} from "../other/Constant";
import ItemLabel from "../other/ItemLabel";
import MissionBlock from "./mission/_MissionBlock";
import HelpBlock from "./help/_HelpBlock";
import SwitchBlock from "./switch/_SwitchBlock";

/**
 * Dashboard Data block with title and content
 * @param title
 * @param moreLink
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const DashboardBlock = ({title, moreLink, children}) => {
    return(
        <div className={"dashboard_block_wrapper"}>
            {moreLink && <Link className={"dashboard_block_more_link"} to={moreLink}>Tout voir</Link>}
            {title && <h2>{title}</h2>}
            {children}
        </div>
    )
}

/**
 * Dashboard Data statistics
 * @param title
 * @param promise
 * @param value
 * @returns {JSX.Element}
 * @constructor
 */
const DashboardStatBlock = ({title, promise, value = null}) => {
    return(
        <div className={"dashboard_item_stat"}>
            <div>
                <Suspense fallback={"..."}>
                    <Await resolve={promise}>
                        {(data) => value === null ?data.length:value}
                    </Await>
                </Suspense>
            </div>
            <span>{title}</span>
        </div>
    )
}

const Dashboard = () => {

    //get loader data
    //const { missions, helps, switchs, pendingMatchs, pendingHelpMatchs, pendingSwitchMatchs } = useLoaderData()
    const loaderPromises = useLoaderData()
    const {user} = useOutletContext();

    const [missionsState, setMissionsState] = useSetState()
    const [matchState, setMatchState] = useState(0)

    useEffect(() => {

        if(user.profileType === PROFIL_TYPE_RECRUTEUR){
            //format data for profil type Recruteur

            //missions datas
            Promise.all([loaderPromises.missions.active, loaderPromises.missions.closed]).then((missionsPromise) =>{
                const [active, closed] = missionsPromise

                setMissionsState({
                    active : active,
                    closed : closed
                })
            })

        }else{
            //format data for profile type candidat

            //missions datas
            Promise.all([loaderPromises.missions.active, loaderPromises.missions.agreement]).then((missionsPromise) =>{
                const [active, agreement] = missionsPromise

                setMissionsState({
                    active : active,
                    agreement : agreement
                })
            })

        }


    }, [loaderPromises]);

    return(
        <div className={"dashboard_page_wrapper"} data-profil={user?.profileType}>

            {user.profileType === Constant.PROFIL_TYPE_RECRUTEUR &&
                <>
                    <div className={"bloc_title_info"}>
                        <h2>Bonjour {user.firstname} !</h2>
                        <p>Je publie des missions</p>
                    </div>

                    <div id={"dashboard_stat_block"}>
                        <DashboardStatBlock title={"Mes matchs"} promise={loaderPromises.allMatchsPromise}/>
                        <DashboardStatBlock title={"En discussion"} promise={loaderPromises.acceptedMatchPromise}/>
                        <DashboardStatBlock title={"Mes accords"} promise={loaderPromises.agreementMatchPromise}/>
                    </div>
                </>
            }

            {user.profileType === Constant.PROFIL_TYPE_CANDIDAT &&
                <>
                    <div className={"bloc_title_info"}>
                        <h2>Bonjour {user.firstname} !</h2>
                        <p>Je propose mes compétences</p>
                    </div>

                    <div id={"dashboard_stat_block"}>
                        <DashboardStatBlock title={"Mes matchs"} promise={loaderPromises.pendingMatchs}/>
                        <DashboardStatBlock title={"En discussion"} promise={loaderPromises.missions.active}/>
                        <DashboardStatBlock title={"Mes Accords"} promise={loaderPromises.missions.agreement}/>
                    </div>
                </>
            }

            <DashboardBlock>
                <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                    <Await resolve={loaderPromises.pendingMatchs} errorElement={<p>Error loading Data !</p>}>
                        {(matchs) => {
                            if(matchs.length > 0)
                                return <SingleMatchBlock count={matchs.length} link={"/matchs"}/>
                            else
                                return <GenericEmptyItem title={"Bientôt vos prochains matchs"}/>
                        }}
                    </Await>
                </Suspense>
            </DashboardBlock>

            <DashboardBlock title={"Missions en cours"} moreLink={"/missions"}>
                <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                    <Await resolve={loaderPromises.missions.active} errorElement={<p>Error loading Data !</p>} >
                        {(missions) => {
                            if (missions.length > 0)
                                return (missions.map((mission, key) => <MissionBlock {...mission}/>))
                            else
                                return (<GenericEmptyItem title={"Aucune mission en cours pour le moment"}/>)
                        }}
                    </Await>
                </Suspense>
            </DashboardBlock>

            <DashboardBlock title={"Aides en cours"} moreLink={"/aides"}>
                <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                    <Await resolve={loaderPromises.helps} errorElement={<p>Error loading Data !</p>} >
                        {(helps) => {
                            if(helps.length > 0)
                                return helps.map((help, key) => <HelpBlock {...help}/>)
                            else
                                return <GenericEmptyItem title={"Aucune aide pour le moment"}/>
                        }}
                    </Await>
                </Suspense>
            </DashboardBlock>

            <DashboardBlock title={"Échanges en cours"} moreLink={"/echanges"}>
                <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                    <Await resolve={loaderPromises.switchs} errorElement={<p>Error loading Data !</p>} >
                        {(switchs) => {
                            if(switchs.length > 0){
                                return( switchs.map((s, key) => <SwitchBlock {...s}/>) )
                            }else{
                                return( <GenericEmptyItem title={"Aucun échange de permanence pour le moment"}/> )
                            }
                        }}
                    </Await>
                </Suspense>
            </DashboardBlock>

        </div>
    )
}

export default Dashboard
