import Api from "../helper/api";
import {PROFIL_TYPE_CANDIDAT, PROFIL_TYPE_RECRUTEUR, STEP_AGREEMENT, STEP_PENDING, STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED} from "../other/Constant";

export const getUserProfileData = async (context) => {

    const api = new Api()
    let promises = {}

    //data for candidate
    if(context.state.user.profileType === PROFIL_TYPE_CANDIDAT){

        const missionsActive = api.getUserMissions([STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED], true,false)
        const missionsAgreement = api.getUserMissions([STEP_AGREEMENT], true,false)

        const missionsPromise = {
            active : missionsActive,
            agreement : missionsAgreement
        }

        const helpPromise = api.getHelps(true, false, null, context.state.user.id)

        const switchPromise = api.getUserSwitchDuties(true, false, null, context.state.user.id)

        const pendingMatchsPromise = api.getMissionLinkings(null,[STEP_PENDING,STEP_SENDER_ACCEPTED])

        promises = {
            missions : missionsPromise,
            helps : helpPromise,
            switchs : switchPromise,
            pendingMatchs : pendingMatchsPromise
        }

    }else if(context.state.user.profileType === PROFIL_TYPE_RECRUTEUR){

        //data for recruiter
        const missionsActive = api.getUserMissions(null, true,false)
        const missionsClosed = api.getUserMissions(null, false,true)

        const missionsPromise = {
            active : missionsActive,
            closed : missionsClosed
        }

        const helpPromise = api.getHelps(true, false, null, context.state.user.id)
        const switchPromise = api.getUserSwitchDuties(true, false, null, context.state.user.id)

        const pendingMatchsPromise = api.getMissionLinkings(null,STEP_RECIPIENT_ACCEPTED)
        const acceptedMatchPromise = api.getMissionLinkings(null,STEP_SENDER_ACCEPTED)
        const agreementMatchPromise = api.getMissionLinkings(null,STEP_AGREEMENT)

        const allMatchsPromise = api.getMissionLinkings()

        promises = {
            missions : missionsPromise,
            helps : helpPromise,
            switchs : switchPromise,
            pendingMatchs : pendingMatchsPromise,
            acceptedMatchPromise : acceptedMatchPromise,
            agreementMatchPromise : agreementMatchPromise,
            allMatchsPromise : allMatchsPromise
        }
    }

    return promises
}
