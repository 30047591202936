import React, {useEffect, useState, Suspense} from "react";
import {Await, useLoaderData, useOutletContext, useRevalidator} from "react-router-dom";

import FilterButtonBar from "../../other/FilterButtonBar";

import SlidingPanel from "../_sliding_panel";
import {GenericEmptyItem} from "../../other/GenericBlock";
import {useSetState} from "react-use";
import AddHelpRequest from "../../slidingContent/AddHelpRequest";
import HelpBlock, {HelpFullBlock} from "./_HelpBlock";
import AddNoteToHelp from "../../slidingContent/addNoteToHelp";

const Helps = () => {

    const [filterValue, setFilterValue] = useState(0);

    const {helps, urgentHelps, userHelps, userApplications} = useLoaderData()
    const {user} = useOutletContext();

    const [helpsState, setHelpsState] = useSetState()

    const revalidator = useRevalidator()

    useEffect(() => {

        helps.then((helps) => setHelpsState({helps}))
        urgentHelps.then((urgent_helps) => setHelpsState({urgent_helps}))
        userHelps.then((user_helps) => setHelpsState({user_helps}))
        userApplications.then((user_applications) => setHelpsState({user_applications}))

    }, [helps, urgentHelps, userHelps, userApplications]);

    //sliding panel
    const [showSlidingPanel, setShowSlidingPanel] = useState(false);

    return(
        <div id={"helps_wrapper"}>

            <FilterButtonBar filters={[
                {value: "help", label: `Demandes des confrères`, count: helpsState.helps?.length},
                {value: "user_help", label: `Mes demandes`, count: helpsState.user_helps?.length},
                {value: "user_application", label: `J'aide`, count: helpsState.user_applications?.length},
                {value: "user_history", label: `Historique`}
            ]} setFilterValue={setFilterValue}/>

            <div className={"text-center"}>
                <button className={"cta full blue"} onClick={() => setShowSlidingPanel(true)}>Demander de l'aide</button>
            </div>

            {filterValue === 0 &&
                <div id={"help_current"} className={"filter_container"}>

                    <div className={"bloc_title_info"}>
                        <p>Retrouvez ici toutes les demandes d'aide confraternelle de la communauté autour de vous.</p>
                    </div>

                    <div className={"help_block_wrapper generic_data_wrapper"}>

                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={helps}>
                                {(helps) => {
                                    if (helps.length > 0)
                                        return (helps.map((help, key) => <HelpFullBlock {...help} key={`help_${help.id}`}/>))
                                    else
                                        return (<GenericEmptyItem title={"Aucune demande pour le moment"}/>)
                                }}
                            </Await>
                        </Suspense>

                    </div>
                </div>
            }

            {filterValue === 1 &&
                <div id={"help_user_help"} className={"filter_container"}>
                    <div className={"help_block_wrapper"}>

                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={userHelps}>
                                {(helps) => {
                                    if (helps.length > 0)
                                        return (helps.map((help, key) => <HelpFullBlock {...help} key={`help_${help.id}`}/>))
                                    else
                                        return (<GenericEmptyItem title={"Aucune demande pour le moment"}/>)
                                }}
                            </Await>
                        </Suspense>

                    </div>
                </div>
            }

            {filterValue === 2 &&
                <div id={"help_application"} className={"filter_container"}>
                    <div className={"help_block_wrapper"}>

                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={userApplications}>
                                {(applications) => {
                                    if (applications.length > 0)
                                        return (applications.map((application, key) => <HelpFullBlock {...application.help} key={`help_${application.help.id}`}/>))
                                    else
                                        return (<GenericEmptyItem title={"Aucune candidature pour le moment"}/>)
                                }}
                            </Await>
                        </Suspense>

                    </div>
                </div>
            }

            {filterValue === 3 &&
                <div id={"help_application"} className={"filter_container"}>
                    <div className={"help_block_wrapper"}>
                        <GenericEmptyItem title={"Aucune candidature pour le moment"}/>
                    </div>
                </div>
            }

            <SlidingPanel show={showSlidingPanel} handleClose={() => setShowSlidingPanel(false)}>
                <AddHelpRequest user={user} handleClose={() => {
                    revalidator.revalidate()
                    setShowSlidingPanel(false)
                }}/>
            </SlidingPanel>


        </div>
    )
}

export default Helps