import React, {useEffect, useState, Suspense} from "react";
import {Await, useLoaderData, useOutletContext} from "react-router-dom";

import FilterButtonBar from "../../other/FilterButtonBar";
import MissionBlock from "./_MissionBlock";

import SlidingPanel from "../_sliding_panel";
import AddMission from "../../slidingContent/addMission";
import {GenericEmptyItem} from "../../other/GenericBlock";
import {sortMissionByStatus, sortMissionCandidatByStatus} from "../../helper/missionHelper";
import {useSetState} from "react-use";
import {PROFIL_TYPE_RECRUTEUR} from "../../other/Constant";

import SwipeableTabs from 'react-swipeable-tabs';

const Missions = () => {

    //api and context
    const {missions} = useLoaderData()
    const {user} = useOutletContext();

    return(
        <div id={"missions_wrapper"}>
            <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                <Await resolve={missions}>
                    {(missions) => {
                        if(user.profileType === PROFIL_TYPE_RECRUTEUR)
                            return( <MissionsCurrent user={user} missions={missions}/> )
                        else
                            return( <MissionsCandidat user={user} missions={missions}/> )
                    }}
                </Await>
            </Suspense>
        </div>
    )
}

const MissionsCurrent = ({missions, user}) => {

    //const [filterValue, setFilterValue] = useState("mission"); //filter
    const [filterValue, setFilterValue] = useState(0); //filter
    const [missionsState, setMissionsState] = useSetState() //mission listings
    const [showSlidingPanel, setShowSlidingPanel] = useState(false); //sliding panel

    /**
     * close panel and add new mission to listing
     * @param mission
     */
    const handleClosePanel = (mission = null) => {

        if(mission !== null){

            if(mission.published)
                setMissionsState({
                    published : [...missionsState.published, mission]
                })
            else
                setMissionsState({
                    draft : [...missionsState.draft, mission]
                })
        }

        setShowSlidingPanel(false)
    }

    /**
     * Filter mission by state
     */
    useEffect(() => {
        setMissionsState(sortMissionByStatus(missions))
    }, [missions]);

    return(
        <>
            <FilterButtonBar filters={[
                {value: "missions", label: `Missions publiées`, count: missionsState.published?.length},
                {value: "draft", label: `Brouillons`, count: missionsState.draft?.length},
                {value: "closed", label: `Missions terminées`, count: missionsState.closed?.length},
            ]} setFilterValue={setFilterValue}/>

            <div className={"text-center"}>
                <button className={"cta full blue"} onClick={() => setShowSlidingPanel(true)}>
                    Publier une mission
                </button>
            </div>

            {filterValue === 0 &&
                <div id={"mission_current"} className={"filter_container"}>
                    <div className={"mission_block_wrapper"}>
                        {missionsState.published?.length > 0 && missionsState.published.map((mission, key) => <MissionBlock {...mission} key={`mission_${mission.id}`} notification={mission.linkings.length > 0 ? "match" : ""}/>)}
                        {missionsState.published?.length === 0 && <GenericEmptyItem title={"Aucune mission pour le moment"}/>}
                    </div>
                </div>
            }

            {filterValue === 1 &&
                <div id={"mission_draft"} className={"filter_container"}>
                    <div className={"mission_block_wrapper"}>
                        {missionsState.draft?.length > 0 && missionsState.draft.map((mission, key) => <MissionBlock {...mission} key={`mission_${mission.id}`} /*notification={unreadMatch > 0 ?"match":""}*//>)}
                        {missionsState.draft?.length === 0 && <GenericEmptyItem title={"Aucune mission pour le moment"}/>}
                    </div>
                </div>
            }

            {filterValue === 2 &&
                <div id={"mission_ended"} className={"filter_container"}>
                    <div className={"mission_block_wrapper"}>
                        {missionsState.closed?.length > 0 && missionsState.closed.map((mission, key) => <MissionBlock {...mission} key={`mission_${mission.id}`} /*notification={unreadMatch > 0 ?"match":""}*//>)}
                        {missionsState.closed?.length === 0 && <GenericEmptyItem title={"Aucune mission pour le moment"}/>}
                    </div>
                </div>
            }

            <SlidingPanel show={showSlidingPanel} handleClose={() => setShowSlidingPanel(false)}>
                <AddMission user={user} handleClose={handleClosePanel}/>
            </SlidingPanel>
        </>
    )
}

const MissionsCandidat = ({missions, user}) => {

    const [filterValue, setFilterValue] = useState(0); //filter
    const [missionsState, setMissionsState] = useSetState() //mission listings

    /**
     * Filter mission by state
     */
    useEffect(() => {
        setMissionsState(sortMissionCandidatByStatus(missions))
    }, [missions]);

    return(
        <>

            <FilterButtonBar filters={[
                { value : "interest", label : `Intérêts`, count : missionsState.interest?.length},
                { value : "discuss", label :`Discussion`, count : missionsState.discuss?.length},
                { value : "agreement", label : `Accord`, count : missionsState.agreement?.length},
                { value : "closed", label : `Terminée`, count : missionsState.closed?.length},
            ]} setFilterValue={setFilterValue}/>

            {filterValue === 0 &&
                <div id={"mission_interest"} className={"filter_container"}>
                    <div className={"mission_block_wrapper"}>
                        {missionsState.interest?.length > 0 && missionsState.interest.map( (match, key) => <MissionBlock {...match.mission} key={`mission_${match.mission.id}`} link={`/matchs/mission/${match.id}`}/>)}
                        {missionsState.interest?.length === 0 && <GenericEmptyItem title={"Retrouvez ici les missions qui vous interesse"}/>}
                    </div>
                </div>
            }

            {filterValue === 1 &&
                <div id={"mission_discuss"} className={"filter_container"}>
                    <div className={"mission_block_wrapper"}>
                        {missionsState.discuss?.length > 0 && missionsState.draft.map( (match, key) => <MissionBlock {...match.mission} key={`mission_${match.mission.id}`} link={`/matchs/mission/${match.id}`}/>)}
                        {missionsState.discuss?.length === 0 && <GenericEmptyItem title={"Retrouvez ici les missions en discussion"}/>}
                    </div>
                </div>
            }

            {filterValue === 2 &&
                <div id={"mission_agrement"} className={"filter_container"}>
                    <div className={"mission_block_wrapper"}>
                        {missionsState.agreement?.length > 0 && missionsState.agreement.map( (match, key) => <MissionBlock {...match.mission} key={`mission_${match.mission.id}`} link={`/matchs/mission/${match.id}`}/>)}
                        {missionsState.agreement?.length === 0 && <GenericEmptyItem title={"Retrouvez ici les missions en discussion"}/>}
                    </div>
                </div>
            }

            {filterValue === 3 &&
                <div id={"mission_closed"} className={"filter_container"}>
                    <div className={"mission_block_wrapper"}>
                        {missionsState.closed?.length > 0 && missionsState.closed.map( (match, key) => <MissionBlock {...match.mission} key={`mission_${match.mission.id}`} link={`/matchs/mission/${match.id}`}/>)}
                        {missionsState.closed?.length === 0 && <GenericEmptyItem title={"Retrouvez ici les missions en discussion"}/>}
                    </div>
                </div>
            }
        </>
    )
}

export default Missions