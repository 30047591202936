import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";
import MultiSelect from "./multiSelect";
import {useForm} from "react-hook-form";
import Api from "../helper/api";
import {useSetState} from "react-use";
import {getItemFromArrayById} from "../helper/formUtils";
import {useDidUpdate, useForceUpdate} from "../helper/hook";

const FormSpecialization = ({user, onFormSubmit, onFormCancel, specialization}) => {

    //react hook form
    const {register,formState: { errors }, trigger, handleSubmit, reset, getValues, setValue, watch, resetField} = useForm();

    //api
    const api = new Api();

    //Form State
    const [formData, setFormData] = useSetState({
        specializationList : [],
        experienceYear : [],
        updateUserField : false
    })

    //OnComponentDidMount -> Fetch form Data
    const fetchFormData = useCallback(async () => {
        const specializationListData = await api.getSpecializationsList();
        const experienceYear = await api.getExperienceYearList();

        setFormData({
            specializationList : specializationListData,
            experienceYear : experienceYear,
            updateUserField : true
        })
    })

    useLayoutEffect( () => {
        fetchFormData()
    }, [])

    //Set user or item to edit values
    useEffect(() => {

        if(!formData.updateUserField)
            return

        // set item data
        if(specialization !== null){
            setValue("specialization", specialization.specialization.id )
            setValue("description", specialization.description ?? "")
            setValue("experience", specialization.experience ? specialization.experience.id : "")
        }

        setFormData({
            updateUserField : false
        })

    },[formData.updateUserField])

    //form cancel
    const handleCancel = () => {
        resetField("specialization")
        resetField("experience")
        resetField("description")
        onFormCancel()
    }

    //form submit
    const handleFormSubmit = async () => {

        const is_valid = await trigger()

        if(is_valid){
            const data = getValues()
            data.specialization = getItemFromArrayById(data.specialization, formData.specializationList)
            data.experience = getItemFromArrayById(data.experience, formData.experienceYear)
            onFormSubmit(data)
        }

    }

    //update component on specialization prop change
    useDidUpdate(() => {
        setFormData({ updateUserField : true })
    },[specialization])

    return(
        <div className={"form"}>

            <div className={"form_row_wrapper type3"}>
                <label>Quelle spécialisation souhaitez-vous ajouter ?</label>
                <select name={"specialization"} id={"specialization"} defaultValue={""} {...register("specialization",{
                    required : "Veuillez renseigner cette information"
                })}>
                    <option value="" disabled={true}>Spécialisation</option>
                    {formData.specializationList.map((c,index) => {
                        return(<option value={c.id} key={index}>{c.name}</option>)
                    })}
                </select>
                <div className="error">{errors.specialization && errors.specialization.message}</div>
            </div>

            <div className={"form_row_wrapper type3"}>
                <label>Expérience</label>
                <select name={"experience"} id={"experience"} defaultValue={""} {...register("experience",{
                    required : "Veuillez renseigner cette information"
                })}>
                    <option value="" disabled={true}>Expérience</option>
                    {formData.experienceYear.map((c,index) => {
                        return(<option value={c.id} key={index}>{c.name}</option>)
                    })}
                </select>
                <div className="error">{errors.experience && errors.experience.message}</div>
            </div>

            <div className={"form_row_wrapper type3"}>
                <label>Description</label>
                <textarea name={"description"}{...register("description",{
                    required : "Veuillez renseigner cette information"
                })}></textarea>
                <div className="error">{errors.description && errors.description.message}</div>
            </div>

            <div className={"cta_wrapper text-right"}>
                <button className={"cta full blue"} onClick={handleSubmit(handleFormSubmit)}>
                    {specialization === null && <>Créer la spécialisation</>}
                    {specialization !== null && <>Valider les modifications</>}
                </button>
                <a className={"cta full blue reverse"} onClick={handleCancel}>Annuler</a>
            </div>
        </div>
    )
}

FormSpecialization.defaultProps = {
    user: {},
    onFormSubmit : (data) => console.log("onFormSubmit is not defined -> ",data),
    handleCancel : () => console.log("handleCancel is not defined"),
    specialization : null
}

export default FormSpecialization