import React, {useContext} from "react";
import {Link, useOutletContext} from "react-router-dom";
import Api from "../../helper/api";
import * as Constant from "../../other/Constant";
import {AuthContext} from "../../userAuth/AuthContext";

const ProfileMenu = () => {

    const {user} = useOutletContext();
    const {updateUserInGlobalContext, logout} = useContext(AuthContext)

    const handleUserChange = (type) => {
        const api = new Api()

        api.UpdateUser(user.id, {
            profileType : type
        }).then((user) => updateUserInGlobalContext(user))

    }

    return(
        <div id={"profilemenu_page_wrapper"} className={user.profileType === Constant.PROFIL_TYPE_CANDIDAT ? "candidate":"recruiter"}>

            <div className={"user_picture"}>
                <div className={"picture"}>{user?.imageName && <img src={process.env.REACT_APP_API_URL+"/"+user.imageName}/> }</div>
                <div className={"name"}>{user?.firstname} {user?.lastname}</div>
            </div>

            <nav>
                <Link to={"/profile/user"}><span>P</span>Mon profil</Link>
                <Link to={"#0"}><span>C</span>Mon cabinet</Link>
                <Link to={"#0"}><span>A</span>Mon abonnement</Link>
                {user.profileType === Constant.PROFIL_TYPE_CANDIDAT &&
                    <Link to={"#0"} className={"profile_switch"} onClick={() => handleUserChange(Constant.PROFIL_TYPE_RECRUTEUR)}>Aller vers le profile<br/>"Je propose des missions"</Link>
                }
                {user.profileType === Constant.PROFIL_TYPE_RECRUTEUR &&
                    <Link to={"#0"} className={"profile_switch"} onClick={() => handleUserChange(Constant.PROFIL_TYPE_CANDIDAT)}>Aller vers le profile<br/>"Je propose mes compétences"</Link>
                }
                <Link to={"#0"}><span>P</span>Mes paramètres</Link>
                <Link to={"#0"}><span>L</span>Légal</Link>
                <Link to={"#0"} className={"logout"} onClick={logout}>Se déconnecter</Link>
            </nav>

        </div>
    )
}

export default ProfileMenu;