import React, {useContext, useEffect, useRef, useState} from "react";
import {AuthContext} from "./userAuth/AuthContext";
import {Link, useNavigate} from "react-router-dom";

const Header = (props) => {

    const {state: ContextState} = useContext(AuthContext)
    const mobileMenuRef = useRef(null);

    const navigate = useNavigate()

    const toggleMobileMenu = () => {
        mobileMenuRef.current.classList.toggle("open");
    }

    return(
        <header>
            {ContextState.isLoggedIn && <div id={"burger_menu"} onClick={toggleMobileMenu}/>}
            <div className={"logo"}/>
            {ContextState.isLoggedIn &&
                <>
                    <Link id={"notification"} to={"/notification"}/>
                    <div id={"profile"} onClick={() => navigate("/profile")}>
                        {props.user?.imageName && <img src={process.env.REACT_APP_API_URL+"/"+props.user.imageName}/> }
                    </div>
                </>
            }

            <div id={"mobile_menu_wrapper"} ref={mobileMenuRef}>
                <button id={"mobile_menu_close"} onClick={toggleMobileMenu}/>

                <nav>
                    <ul>
                        <li>
                            <Link to={"/dashboard"} onClick={toggleMobileMenu}>Dashboard</Link>
                        </li>
                        <li>
                            <Link to={"/missions"} onClick={toggleMobileMenu}>Missions</Link>
                        </li>
                        <li>
                            <Link to={"/aides"} onClick={toggleMobileMenu}>Aide confraternelle</Link>
                        </li>
                        <li>
                            <Link to={"/echanges"} onClick={toggleMobileMenu}>Échanges de permanences</Link>
                        </li>
                        <li>
                            <Link to={"/questions"} onClick={toggleMobileMenu}>Questions juridiques</Link>
                        </li>
                        <li>
                            <Link to={"/entreprise"} onClick={toggleMobileMenu}>Entreprise</Link>
                        </li>
                    </ul>
                </nav>
            </div>

        </header>
    )
}

Header.defaultProps = {
    user: {}
}

export default Header