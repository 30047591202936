const ItemLabel = ({code, type}) => {

    const exploded_title = code.split("")

    return (
        <div className={`item_label ${type}`}>
            {exploded_title.map((t) => <span>{t}</span>)}
        </div>
    )
}

export default ItemLabel