import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ItemLabel from "../../other/ItemLabel";
import {AuthContext} from "../../userAuth/AuthContext";
import {STEP_AGREEMENT, STEP_DONE, STEP_PENDING, STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED} from "../../other/Constant";

const SwitchBlock = (props) => {

    const navigate = useNavigate()
    const {state} = useContext(AuthContext)
    const userIsOwner = props.user && props.user.id == state.user.id

    const [status, setStatus] = useState("")

    useEffect(() => {
        let maxLinkingStep = 0
        if(props.linkings){
            props.linkings.forEach((l) => {
                if(l.step > maxLinkingStep)
                    maxLinkingStep = l.step
            })
        }

        switch(maxLinkingStep){

            case STEP_PENDING :
                setStatus("")
                break;

            case STEP_RECIPIENT_ACCEPTED :
                setStatus("Matching en attente")
                break;

            case STEP_SENDER_ACCEPTED :
                setStatus("En discussion")
                break;

            case STEP_AGREEMENT :
                setStatus("Accord")
                break;

            case STEP_DONE :
                setStatus("Terminée")
                break;

            default :
                setStatus("")
        }

    },[])

    return(
        <div className={`switch_block_item ${userIsOwner?"own":""}`} onClick={() => {navigate(`/echanges/${props.id}`)}}>
            <ItemLabel code={props.type.code} type={props.type.name}/>
            <div className={"content"}>
                <div className={"title"}>{props.type.name}</div>
                <div className={"description"}>
                    <span className={"state"}>{status}</span>
                </div>
            </div>
        </div>
    )
}

SwitchBlock.defaultProps = {
    switchId : 0,
    title : "no title provided",
    description : "no description provided",
    linkings : [],
    createdOn : "1970-01-01 00:00:00",
}

export default SwitchBlock