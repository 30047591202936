import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import Api from "../helper/api";

const AddNoteToHelp = ({handleClose}) => {

    const {register,formState: { errors }, handleSubmit, reset, setValue} = useForm();
    const api = new Api();

    //fetch data from api
    useEffect(() => {

    },[])

    //handle form surbmit
    const handleHelpSubmit = (data) => {

        /*api.createHelp(
            data.title,
            data.description,
            data.type,
            data.date,
            data.urgent,
            data.court,
            data.location.address,
            data.bar
        ).then((data) => handleClose())*/
    }

    return (
        <div id={"addnotetohelp_wrapper"}>
            <div className={"bloc_title_info"}>
                <h2>Ajoutez un message à votre proposition de compétence</h2>
            </div>

            <div id={"addnotetohelp_form"} className={"profil_block"}>
                <form onSubmit={handleSubmit(handleHelpSubmit)}>
                    <div className={"form_row_wrapper type3"}>
                        <label>Type</label>
                        <textarea name={"message"} id={"message"} {...register("message")}/>
                        <div className="error">{errors.type && errors.type.message}</div>
                    </div>
                </form>
            </div>

        </div>
)
}

export default AddNoteToHelp