import {GenericEmptyItem, SingleMatchBlock, SingleProfilTalentBlock} from "../../other/GenericBlock";
import React from "react";

const SingleItemMatch = ({type, itemId, pendingMatchs}) => {

    const typeValue = {
        help : {
            class : 'help',
            matchLink : `/aides/${itemId}/matchs`
        },
        switch : {
            class : 'switch',
            matchLink : `/echanges/${itemId}/matchs`
        },
        mission : {
            class : 'mission',
            matchLink : `/missions/${itemId}/matchs`
        }
    }

    return (
        <div id={`single_${typeValue[type].class}_match`} className={"generic_data_wrapper"}>
            {pendingMatchs === 0 && <GenericEmptyItem title={"Bientôt vos prochains matchs"}/>}
            {pendingMatchs !== 0 && <SingleMatchBlock count={pendingMatchs} link={typeValue[type].matchLink}/>}
        </div>
    )
}

export default SingleItemMatch