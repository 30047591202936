import React, {useEffect, useState} from 'react'
import {Outlet, useLoaderData, useRevalidator, useLocation} from "react-router-dom";

import Header from "./header"
import Footer from "./footer"

import ValidatePhoneScreen from "./protected_pages/startScreen/validatePhoneScreen";
import OptionScreenChoice from "./protected_pages/startScreen/OptionScreenChoice";

const Layout = () => {
    const [lastRoute, setLastRoute] = useState("")
    const [currentRoute, setCurrentRoute] = useState("")

    //handle registration step if not completed
    const {isLoggedIn, showVerifiedPhoneScreen, showOptionChoiceScreen, user} = useLoaderData()
    const revalidator = useRevalidator()

    //scroll to top on route change
    let location = useLocation();

    useEffect( () => {

        let route = location.pathname.substring(1)
        route = route === "" ? "index" : route

        if (lastRoute !== "" && route !== lastRoute) {
            window.scrollTo(0, 0)
        }

        setLastRoute(route)
        setCurrentRoute(route)

        //refresh loader data
        revalidator.revalidate()
    },[location])

    return (

            <div className="page_wrapper" id={"page_" + currentRoute + "_wrapper"} data-profil={user?.profileType}>
                <Header user={user}/>

                <div className="content_wrapper" id={"content_" + currentRoute + "_wrapper"}>
                    {isLoggedIn && showVerifiedPhoneScreen && <ValidatePhoneScreen/>}
                    {isLoggedIn && !showVerifiedPhoneScreen && showOptionChoiceScreen && <OptionScreenChoice user={user}/>}
                    {!showVerifiedPhoneScreen && !showOptionChoiceScreen && <Outlet context={{
                        user : user
                    }} />}
                </div>

                {/*<Footer/>*/}
            </div>

    );

}
export default Layout;
