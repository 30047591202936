import React, {useCallback, useState} from "react";
import Select from "react-select";

const MultiSelect = ({name, id, placeholder, options, isMulti, closeMenuOnSelect, defaultValues, getValues}) => {

    //handle multiselect change
    const [values, setValues] = useState([])

    const handleMultiSelectChange = useCallback((values) => {

        let formattedValues = []
        values.map(item => {
            formattedValues = [...formattedValues, {name: item.label,id: item.value}]
        })

        setValues(formattedValues)
        getValues(formattedValues)
    }, [values])

    return(
        <Select name={name} id={id} options={ options }  placeholder={placeholder} isMulti={isMulti} closeMenuOnSelect={closeMenuOnSelect}  onChange={handleMultiSelectChange} value={defaultValues.map(item => ({ label: item.name, value: item.id }))}/>
    )
}

export default MultiSelect

MultiSelect.defaultProps = {
    name : "noname",
    id : "noid",
    options : [],
    isMulti : true,
    closeMenuOnSelect : true,
    defaultValue : [],
    ref : null,
    getValues : () => console.log("getValues is not set")
}