import React, {useEffect, useState, Suspense} from "react";
import {Await, useLoaderData, useNavigate, useOutletContext} from "react-router-dom";

import SlidingPanel from "../_sliding_panel";
import {GenericEmptyItem} from "../../other/GenericBlock";
import FilterButtonBar from "../../other/FilterButtonBar";
import {useSetState} from "react-use";
import {sortMatchsByType} from "../../helper/missionHelper";
import MissionBlock from "../mission/_MissionBlock";
import {PROFIL_TYPE_RECRUTEUR} from "../../other/Constant";

const MatchBlock = ({title, type, Link}) => {

    const navigate = useNavigate()

    return (
        <div className={`mission_item`} onClick={() => navigate(Link)}>
            <div className={"picture"}/>
            <div className={"content"}>
                <div className={"type"}>{type}</div>
                <div className={"title"}>{title}</div>
            </div>
        </div>
    )
}


const Matchs = () => {

    const [filterValue, setFilterValue] = useState(0);
    const {missions, helps, switchs} = useLoaderData()

    const [matchsState, setMatchsState] = useSetState()

    const {user} = useOutletContext()

    useEffect(() => {
        Promise.all([missions,helps,switchs]).then((values) => {
            const [m,h,s] = values

            setMatchsState({
                missions : m.length,
                helps : h.length,
                switchs : s.length
            })

        })

    },[missions,helps,switchs])

    return(
        <div id={"matchs_wrapper"}>

            <div className={"matchs_block_wrapper blue"}>

                <div className={"bloc_title_info"}>
                    <h2>Consultez vos matchs</h2>
                    <p>Bienvenue sur votre profil ! Pour un matching...</p>
                </div>

                <FilterButtonBar filters={[
                    { value : "mission", label : `Missions`, count : matchsState.missions},
                    { value : "help", label :`Aides confraternelles`, count : matchsState.helps},
                    { value : "switch", label : `Échanges de permanence`, count : matchsState.switchs},
                ]} setFilterValue={setFilterValue}/>

            </div>
            {filterValue === 0 &&

                <div className={"mission_block_wrapper"}>
                    <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                        <Await resolve={missions}>
                            {(missions) => {

                                //no missions matches
                                if(missions.length === 0)
                                    return(<GenericEmptyItem title={"Aucun match pour le moment"}/>)

                                if(user.profileType === PROFIL_TYPE_RECRUTEUR)
                                    return(missions.map((match, key) => <MissionBlock key={"match_mission_item_"+key}  {...match.mission} link={`/missions/${match.mission.id}/matchs`}/>))
                                else
                                    return(missions.map((match, key) => <MissionBlock key={"match_mission_item_"+key} {...match.mission} link={`/matchs/mission/${match.id}`}/>))
                            }}
                        </Await>
                    </Suspense>
                </div>

            }

            {filterValue === 1 &&

                <div className={"mission_block_wrapper"}>
                    <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                        <Await resolve={helps}>
                            {(helps) => {
                                if(helps.length === 0)
                                    return(<GenericEmptyItem title={"Consultez les aides ici"}/>)
                                else
                                    return (helps.map((match, key) => <MatchBlock title={`Match ${key}`} type={"Aides"}  itemId={match.id} Link={"/matchs/aide/"}/>))
                            }}
                        </Await>
                    </Suspense>
                </div>

            }

            {filterValue === 2 &&

                <div className={"mission_block_wrapper"}>
                    <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                        <Await resolve={switchs}>
                            {(switchs) => {
                                if(switchs.length === 0)
                                    return(<GenericEmptyItem title={"Consultez les échanges ici"}/>)
                                else
                                    return(switchs.map((match, key) => <MatchBlock title={`Match ${key}`} type={"Échanges"} itemId={match.id} Link={"/matchs/permanence/"}/>))
                            }}
                        </Await>
                    </Suspense>
                </div>

            }
        </div>
    )
}

export default Matchs