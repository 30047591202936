import {GenericEmptyItem, SingleMatchBlock, SingleProfilTalentBlock} from "../../other/GenericBlock";
import React, {useEffect, useState} from "react";

const SingleItemCollaborateur = ({type, linkings, showStopMatchingCta, stopMatching}) => {

    const typeValue = {
        help : {
            class : 'help',
            //matchLink : `/aides/${itemId}/matchs`
        },
        switch : {
            class : 'switch',
            //matchLink : `/echanges/${itemId}/matchs`
        },
        mission : {
            class : 'mission',
            //matchLink : `/missions/${itemId}/matchs`
        }
    }

    const [profilTalent, setProfilTalent] = useState([])

    useEffect(() => {

        let profils = []
        linkings.forEach((l) => profils.push(<SingleProfilTalentBlock itemId={l.id} profil={l.user}/>))
        setProfilTalent(profils)

    }, [linkings]);

    return (
        <div id={`single_${typeValue[type].class}_collab`}>
            <h2>Profils talents</h2>
            {linkings.length === 0 && <GenericEmptyItem title={"Aucun profils pour le moment"}/>}
            {linkings.length > 0 && profilTalent}

            {linkings.length > 0 && showStopMatchingCta &&
                <div className={"cta_wrapper"}>
                    <button className={"cta blue full reverse"} onClick={stopMatching}>J'ai trouvé mon candidat idéal</button>
                </div>
            }
        </div>
    )
}

export default SingleItemCollaborateur