import React, {Suspense, useEffect, useState} from "react";
import Slider from "react-slick";

import SlidingPanel from "./_sliding_panel";
import FilterButtonBar from "../other/FilterButtonBar";
import {Await, useLoaderData, useOutletContext} from "react-router-dom";
import {GenericEmptyItem} from "../other/GenericBlock";

const NotificationItem = ({title, description, date, itemId}) => {
    return(
        <div className={"notification_item"}>
            <div className={"picture"}/>
            <div className={"content"}>
                <div className={"title"}>{title}</div>
                <div className={"description"}>{description}</div>
                <div className={"date"}>{new Date(date).toLocaleDateString("fr-FR")}</div>
            </div>
        </div>
    )
}

const Notifications = () => {

    const [filterValue, setFilterValue] = useState("missions");
    const [showSlidingPanel, setShowSlidingPanel] = useState(false);

    const [missionNotifications, setMissionNotification] = useState([])
    const [helpNotifications, setHelpNotification] = useState([])
    const [communityNotifications, setCommunityNotification] = useState([])
    const [generalNotifications, setGeneralNotification] = useState([])

    const { notifications } = useLoaderData()
    const {user} = useOutletContext();

    useEffect(() => {
        notifications.then((notifications) => {

            const mission_notifications = []
            const help_notifications = []
            const community_notifications = []
            const general_notifications = []

            notifications.forEach((n) => {

                const notificationItem = <NotificationItem title={"Nouvelle notification"} description={"Description par défaut"} date={n.createdOn} itemId={n.id}/>

                switch(n.type){
                    case 1 :
                        mission_notifications.push(notificationItem)
                        break;

                    case 2 :
                        help_notifications.push(notificationItem)
                        break;

                    case 3 :
                        community_notifications.push(notificationItem)
                        break;

                    case 4 :
                        general_notifications.push(notificationItem)
                        break;
                }
            })

            setMissionNotification(mission_notifications)
            setHelpNotification(help_notifications)
            setCommunityNotification(community_notifications)
            setGeneralNotification(general_notifications)

        })
    }, [])

    return(
        <div id={"notifications_wrapper"}>

            <div className={"bloc_title_info"}>
                <h2>Notifications</h2>
                <p>Retrouvez ici toutes vos notifications de missions, aides et autres</p>
            </div>


            <FilterButtonBar filters={[
                { value : "missions", label : `Missions (${missionNotifications.length})`},
                { value : "helps", label : `Aides (${helpNotifications.length})`},
                { value : "community", label : `Communauté (${communityNotifications.length})`},
                { value : "general", label : `Général (${generalNotifications.length})`},
            ]} setFilterValue={setFilterValue}/>

            {filterValue === "missions" &&
                <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                    <Await resolve={notifications} errorElement={<p>Error loading Data !</p>} >
                        <div className={"notification_block_wrapper"}>
                            {missionNotifications.length > 0 && missionNotifications}
                            {missionNotifications.length <= 0 && <GenericEmptyItem title={"Aucune Notification pour le moment"}/>}
                        </div>
                    </Await>
                </Suspense>
            }

            {filterValue === "helps" &&
                <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                    <Await resolve={notifications} errorElement={<p>Error loading Data !</p>} >
                        <div className={"notification_block_wrapper"}>
                            {helpNotifications.length > 0 && helpNotifications}
                            {helpNotifications.length <= 0 && <GenericEmptyItem title={"Aucune Notification pour le moment"}/>}
                        </div>
                    </Await>
                </Suspense>
            }

            {filterValue === "community" &&
                <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                    <Await resolve={notifications} errorElement={<p>Error loading Data !</p>} >
                        <div className={"notification_block_wrapper"}>
                            {communityNotifications.length > 0 && communityNotifications}
                            {communityNotifications.length <= 0 && <GenericEmptyItem title={"Aucune Notification pour le moment"}/>}
                        </div>
                    </Await>
                </Suspense>
            }

            {filterValue === "general" &&
                <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                    <Await resolve={notifications} errorElement={<p>Error loading Data !</p>} >
                        <div className={"notification_block_wrapper"}>
                            {generalNotifications.length > 0 && generalNotifications}
                            {generalNotifications.length <= 0 && <GenericEmptyItem title={"Aucune Notification pour le moment"}/>}
                        </div>
                    </Await>
                </Suspense>
            }




            <SlidingPanel show={showSlidingPanel} handleClose={() => setShowSlidingPanel(false)}>

            </SlidingPanel>

        </div>
    )
}

export default Notifications