import React from "react";
import { useNavigate, useOutletContext} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Api from "../helper/api";

const CommunityRoute = (props) => {

    const { user } = useOutletContext()
    const navigate = useNavigate()
    const handleRejectCGU = () => {
        navigate(-1)
    }

    const handleAcceptCgu = () => {
        const api = new Api()
        api.UpdateUser(user.id,{communityPolicy : true}).then(() => navigate(0))
    }

    if(user.communityPolicy)
        return(<ProtectedRoute>{props.children}</ProtectedRoute>)

    return(
        <div id={"community_cgu_wrapper"}>
            <h1>Vous devez accepter les cgv pour continuer</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean et neque faucibus mi hendrerit blandit vitae at arcu. Duis convallis egestas tempus. Nullam quis purus aliquam, pretium dolor at, elementum ligula. Curabitur eget vulputate leo. Curabitur iaculis tortor metus. Sed sed fringilla felis. Morbi mollis sapien sit amet nisl malesuada, quis semper purus convallis. Suspendisse orci metus, vestibulum in justo non, maximus scelerisque dolor. In dapibus ante ut enim vestibulum convallis. Ut ut auctor ligula.</p>
            <p>Vestibulum malesuada vestibulum ullamcorper. Donec condimentum convallis ornare. In pharetra feugiat lorem, vel feugiat dui pretium sed. Donec ultricies dui ut accumsan malesuada. Morbi scelerisque massa nulla, ut ultrices metus blandit non. Maecenas quis elementum lacus, id tempus eros. Sed sapien dui, congue fermentum dapibus id, ultricies quis ipsum. Nulla suscipit venenatis dictum.</p>
            <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec tellus leo, fermentum eget suscipit eu, lacinia posuere orci. Nulla aliquam rhoncus orci, ac mattis felis blandit in. Nulla commodo ullamcorper hendrerit. Ut arcu arcu, commodo ac lacus nec, sodales elementum metus. Donec euismod ligula eros, nec vehicula massa eleifend a. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In suscipit vulputate faucibus. Integer quis sagittis mi. Integer sed justo justo. Donec ultricies, lacus ac rutrum commodo, libero lacus porta eros, ac feugiat lacus sem ac diam. Maecenas sed risus bibendum lectus varius facilisis at at nunc. Aenean lacinia orci vel erat iaculis, id tincidunt massa volutpat.</p>
            <p>Quisque quis arcu eros. Nulla rutrum lacus eu enim accumsan aliquam. Vestibulum semper placerat dolor sed molestie. Sed vitae ante vulputate, aliquet sem vitae, lacinia enim. Duis arcu purus, maximus in tortor nec, blandit vulputate eros. Phasellus nec massa a mauris blandit pulvinar. Sed ut mauris nec mauris cursus rutrum non nec mauris. Pellentesque tristique nulla scelerisque mi convallis, et varius turpis fringilla. Integer dolor tellus, consectetur ut diam vitae, fermentum volutpat turpis.</p>
            <p>Curabitur lobortis ex non gravida euismod. Nunc vitae sem augue. In mollis dui pellentesque odio vulputate semper. Etiam eu sem sit amet nisi pulvinar finibus quis eu velit. Duis aliquet turpis eget diam rhoncus, sodales dapibus nulla fringilla. Quisque vestibulum eget massa ut vulputate. Quisque sodales magna non dapibus hendrerit. Ut a sapien nisi. Etiam a dignissim quam. Nullam auctor sem in fringilla volutpat. Etiam arcu mauris, vestibulum at ullamcorper id, pretium et odio. Sed pretium nunc purus, in eleifend sem efficitur non. Vestibulum ut dapibus odio.</p>
            <br/>
            <br/>
            <div className={"wta_wrapper"}>
                <a className={"cta full blue"} onClick={handleAcceptCgu}>Accepter les CGU</a>
                <a className={"cta full reverse blue"} onClick={handleRejectCGU}>Refuser les CGU</a>
            </div>
        </div>

    )
}

export default CommunityRoute