import {createBrowserRouter} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import UnprotectedRoute from "./UnprotectedRoute";

import Layout from '../_layout';
import ErrorPage from "../pages/404";

import Signin from "../pages/Signin";
import LostPassword from "../pages/LostPassword";
import Signup from "../pages/Signup";
import Dashboard from "../protected_pages/Dashboard";

import Missions from "../protected_pages/mission/Missions";
import SingleMission from "../protected_pages/mission/SingleMission";
import ProfileMenu from "../protected_pages/profil/ProfileMenu";
import ProfileMission from "../protected_pages/profil/ProfileMission";
import Notifications from "../protected_pages/Notification";
import SingleMatch from "../protected_pages/match/SingleMatch";

//Loaders
import {getMatch, getMissionMatchs, getMission, getMissions} from "../loaders/missionsLoader";
import {getUserProfileData} from "../loaders/dashboardLoader";
import {getUserStepStatus} from "../loaders/layoutLoader";
import {getNotifications} from "../loaders/notificationsLoader";
import Matchs from "../protected_pages/match/Matchs";
import {getHelpMatch, getMatchs, getMissionMatch, getSwitchMatch} from "../loaders/matchsLoader";
import Helps from "../protected_pages/help/Helps";
import {getHelp, getHelpMatchs, getHelps} from "../loaders/helpsLoader";
import SingleHelp from "../protected_pages/help/SingleHelp";
import SingleItemMatchsListing from "../protected_pages/_singleItem/SingleItemMatchsListing";
import Switchs from "../protected_pages/switch/Switchs";
import {getSwitch, getSwitchs} from "../loaders/switchsLoader";
import SingleSwitch from "../protected_pages/switch/SingleSwitch";
import {useEffect} from "react";
import CommunityRoute from "./CommunityRoute";
import SignupSSO from "../pages/SignupSSO";

const router = (contextValue) => createBrowserRouter([
    {
        path: "/",
        element: <Layout/>,
        errorElement: <ErrorPage />,
        loader : () => getUserStepStatus(contextValue),
        children : [
            {
                path: "connexion",
                element : <UnprotectedRoute><Signin/></UnprotectedRoute>
            },
            {
                path: "reset-password",
                element : <UnprotectedRoute><LostPassword/></UnprotectedRoute>
            },
            {
                path: "inscription",
                element : <UnprotectedRoute><Signup/></UnprotectedRoute>
            },
            {
                path: "inscription_sso",
                element : <UnprotectedRoute><SignupSSO/></UnprotectedRoute>
            },
            {
                path : "dashboard",
                element : <ProtectedRoute><Dashboard/></ProtectedRoute>,
                loader : () => getUserProfileData(contextValue)
            },
            //---------------
            //  MISSIONS
            //---------------
            {
                path : "missions",
                element : <ProtectedRoute><Missions/></ProtectedRoute>,
                loader : () => getMissions(contextValue)
            },
            {
                path : "missions/:missionId",
                element : <ProtectedRoute><SingleMission/></ProtectedRoute>,
                loader : getMission
            },
            {
                path : "missions/:missionId/matchs",
                element : <ProtectedRoute><SingleItemMatchsListing/></ProtectedRoute>,
                loader : getMissionMatchs
            },
            //---------------
            //  AIDE
            //---------------
            {
                path: "aides",
                element : <CommunityRoute><Helps/></CommunityRoute>,
                loader : () => getHelps(contextValue)
            },
            {
                path : "aides/:helpId",
                element : <CommunityRoute><SingleHelp/></CommunityRoute>,
                loader : getHelp
            },
            {
                path : "aides/:helpId/matchs",
                element : <CommunityRoute><SingleItemMatchsListing/></CommunityRoute>,
                loader : getHelpMatchs
            },
            //---------------
            //  SWITCH
            //---------------
            {
                path: "echanges",
                element : <CommunityRoute><Switchs/></CommunityRoute>,
                loader : () => getSwitchs(contextValue)
            },
            {
                path : "echanges/:switchId",
                element : <CommunityRoute><SingleSwitch/></CommunityRoute>,
                loader : getSwitch
            },
            {
                path : "echanges/:switchId/matchs",
                element : <CommunityRoute><SingleItemMatchsListing/></CommunityRoute>,
                loader : getHelpMatchs
            },
            //---------------
            //  MATCHS
            //---------------
            {
                path: "matchs",
                element : <ProtectedRoute><Matchs/></ProtectedRoute>,
                loader : () => getMatchs(contextValue)
            },
            {
                path : "matchs/mission/:matchId",
                element : <ProtectedRoute><SingleMatch/></ProtectedRoute>,
                loader : getMissionMatch
            },
            {
                path : "matchs/aide/:matchId",
                element : <ProtectedRoute><SingleMatch/></ProtectedRoute>,
                loader : getHelpMatch
            },
            {
                path : "matchs/permanence/:matchId",
                element : <ProtectedRoute><SingleMatch/></ProtectedRoute>,
                loader : getSwitchMatch
            },
            //---------------
            //  PROFIL
            //---------------
            {
                path : "profile",
                element : <ProtectedRoute><ProfileMenu/></ProtectedRoute>
            },
            {
                path : "profile/user",
                element : <ProtectedRoute><ProfileMission/></ProtectedRoute>
            },
            {
                path : "notification",
                element : <ProtectedRoute><Notifications/></ProtectedRoute>,
                loader : getNotifications
            }
        ]
    },
]);

export default router