import React, {useEffect, useState, Suspense} from "react";
import {Await, useLoaderData, useNavigate} from "react-router-dom";

import {GenericEmptyItem} from "../../other/GenericBlock";
import {CtaReturn} from "../../other/Cta";

const MatchBlock = ({title, compatibility, itemId, type, isLiked}) => {

    const navigate = useNavigate()

    return(
        <div className={`match_item list ${isLiked?"liked":""}`} onClick={() => navigate("/matchs/"+type+"/"+itemId)}>
            <div className={"content"}>
                <div className={"title"}>{title}</div>
                <div className={"tags"}>
                    <span>{compatibility}% compatible</span>
                </div>
            </div>
        </div>
    )
}

const SingleItemMatchsListing = () => {

    const {matchs,type} = useLoaderData()
    const [matchsList, setMatchsList] = useState([]);

    useEffect(() => {
        matchs.then((matchs) => {

            let formatted_matchs = []
            matchs.forEach((match, key) => {
                formatted_matchs.push(<MatchBlock title={`Match ${key}`} compatibility={50} itemId={match.id} type={type} isLiked={match.bookmarks?.length > 0}/>)
            })

            setMatchsList(formatted_matchs)
        })
    },[])

    return(
        <div className={"single_item_match_listing_wrapper"}>
            <div className={"cta_wrapper"}>
                <CtaReturn/>
            </div>

            <div className={"generic_data_wrapper"}>

                <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                    <Await resolve={matchs}>
                        {matchsList.length > 0 && matchsList}
                        {matchsList.length === 0 && <GenericEmptyItem title={"Aucun match pour le moment"}/>}
                    </Await>
                </Suspense>
            </div>

        </div>
    )
}

export default SingleItemMatchsListing