import React, {useEffect, useState, Suspense} from "react";
import {Await, useLoaderData, useOutletContext} from "react-router-dom";

import {GenericEmptyItem} from "../../other/GenericBlock";
import SlidingPanel from "../_sliding_panel";
import SingleItemHeader, {OtherSingleItemHeader} from "../_singleItem/SingleItemHeader";
import SingleItemMatch from "../_singleItem/SingleItemMatch";
import SingleItemDetails from "../_singleItem/SingleItemDetails";

const SingleHelp = () => {

    //state
    const [help, setHelp] = useState([])
    const [userHelp, setUserHelp] = useState(false)
    const [pendingMatchs, setPendingMatchs] = useState(0)
    const [acceptedMatchs, setAcceptedMatchs] = useState(0)

    //filter
    const [showSlidingPanelDetails, setShowSlidingPanelDetails] = useState(false);

    //loaders
    const {helpPromise} = useLoaderData()

    //context
    const {user} = useOutletContext()

    useEffect(() => {

        helpPromise.then((help) => {

            let pendingMatchCount = 0
            let acceptedMatchCount = 0

            help.linkings.forEach((l) => {

                if(l.step === 1)
                    pendingMatchCount++
                else if(l.step === 3)
                    acceptedMatchCount++
            })

            setPendingMatchs(pendingMatchCount)
            setAcceptedMatchs(acceptedMatchCount)

            //help is current user help
            setUserHelp(user.id === help.user.id)
            setHelp(help)
        })
    })

    return(
        <div className={"single_item_wrapper"} id={"single_help_wrapper"}>

            <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                <Await resolve={helpPromise}>

                    {userHelp &&
                        <>
                            <SingleItemHeader singleItem={help} setShowSlidingPanelDetails={setShowSlidingPanelDetails}/>
                            <SingleItemMatch pendingMatchs={pendingMatchs} acceptedMatchs={acceptedMatchs} itemId={help.id} type={"help"}/>

                            <div id={"single_help_cta"} className={"single_item_cta_wrapper"}>
                                <button className={"cta blue full"}>Cette aide est terminée</button>
                                <button className={"cta full"}>Supprimer</button>
                            </div>
                        </>
                    }

                    {!userHelp &&
                        <>
                            <OtherSingleItemHeader singleItem={help}/>
                            <div id={"single_help_cta"} className={"single_item_cta_wrapper"}>
                                <button className={"cta blue full"}>Candidater</button>
                            </div>
                        </>
                    }

                </Await>
            </Suspense>

            <SlidingPanel show={showSlidingPanelDetails} handleClose={() => setShowSlidingPanelDetails(false)}>
                <SingleItemDetails singleItem={help}/>
            </SlidingPanel>

        </div>
    )
}

export default SingleHelp