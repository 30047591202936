//dummy missions
import Api from "../helper/api";
import {PROFIL_TYPE_RECRUTEUR, STEP_AGREEMENT, STEP_RECIPIENT_ACCEPTED} from "../other/Constant";

/**
 * Return all user missions list
 * @returns {Promise<{missions: Promise<*>}>}
 */
export const getMissions = (context) => {

    const api = new Api();
    let missionsPromise

    if(context.state.user.profileType === PROFIL_TYPE_RECRUTEUR)
        missionsPromise = api.getUserMissions([1,3,0,6]);
    else
        missionsPromise = api.getMissionLinkings(null,[1,3,5,6]);

    return {
        missions : missionsPromise
    };
}

/**
 * Return data for current mission
 * @param params - contain missionId
 */
export const getMission = async ({params}) => {

    const api = new Api()
    const missionPromise = api.getMission(params.missionId)

    const pendingMatchPromise = api.getMissionLinkings(params.missionId, 1)
    const acceptedMatchPromise = api.getMissionLinkings(params.missionId, [STEP_RECIPIENT_ACCEPTED, STEP_AGREEMENT])

    return {
        missionPromise : missionPromise,
        pendingMatchPromise : pendingMatchPromise,
        acceptedMatchPromise : acceptedMatchPromise
    }
}

/**
 * Return Matchs linking List for a specific mission
 * @param params - contain missionId
 */
export const getMissionMatchs = async ({params}) => {

    const api = new Api()
    const matchsPromise = api.getMissionLinkings(params.missionId, 1)

    return {
        type : "mission",
        matchs : matchsPromise
    }
}

