import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import {AuthContext} from "../userAuth/AuthContext";
import {useForm} from "react-hook-form";
import {emailPattern} from "../helper/formUtils";
import AppleSignin from 'react-apple-signin-auth';
import {CtaGoogleLogin} from "../other/Cta";
import PasswordInput from "../form/passwordInput";

const Signin = () => {

    //component state
    const [loginError, setLoginError] = useState("");

    //user context
    const { login } = useContext(AuthContext);

    //handle form
    const {register,formState: { errors }, handleSubmit} = useForm();

    /**
     * Classic credential login
     * @param data
     */
    const handleLogin = (data) => {
        setLoginError("")

        login(data.mail, data.password).catch((error) => {
            setLoginError(error.response.data.message)
        });
    }


    /**
     * Apple Login
     */
    const handleLoginApple = (userInfo) => {
        console.log(userInfo)
    }

    return(
        <div className={"connection_wrapper"}>

            <div className={"connection_box"}>
                <h1>Se connecter</h1>
                <form id={"connection_form"} onSubmit={handleSubmit(handleLogin)}>
                    <div className={"form_row_wrapper type3"}>
                        <label htmlFor={"mail"}>Email</label>
                        <input type={"text"} name={"mail"} id={"mail"} placeholder={"Adresse mail"} {...register("mail", {
                            required: "Veuillez renseigner votre adresse e-mail",
                            pattern: {
                                value: emailPattern,
                                message: "Veuillez saisir une adresse e-mail valide"
                            }
                        })}/>
                        <div className={"error"}>{errors.mail && errors.mail.message}</div>
                    </div>

                    <div className={"form_row_wrapper type3"}>
                        <label htmlFor={"password"}>Mot de passe</label>
                        <PasswordInput name={"password"} id={"password"} placeholder={"Mot de passe"} register={register}/>
                        <div className={"error"}>{errors.password && errors.password.message}</div>
                    </div>

                    <div className={"connection_link text-right"}>
                        <Link to={"/reset-password"}>Mot de passe oublié ?</Link>
                    </div>

                    <div className={"error text-center"}>{loginError}</div>

                    <div className={"cta_wrapper"}>
                        <input className={"cta blue full"} type={"submit"} value={"Se connecter"}/>
                    </div>
                </form>

                <div className={"cta_wrapper"}>
                    <CtaGoogleLogin/>
                    {/*<AppleSignin
                            authOptions={{
                                clientId: 'com.esterlaw.web',
                                scope: 'email name',
                                state: 'state',
                                nonce: '123456789',
                                usePopup: true,
                                redirectURI: "https://example.com"
                            }}
                            className="cta_social_login linkedin"
                            onSuccess ={(userInfo) => handleLoginApple(userInfo)}
                            noDefaultStyle
                        >
                            <span>Apple</span>
                        </AppleSignin>*/}
                </div>

                <div className={"separator"}>
                    <div/>
                    <span>Ou</span>
                    <div/>
                </div>

                <div className={"connection_link"}>
                    Vous n’êtes pas encore inscrit ? <Link className={"connection_small_link"} to={"/inscription"}>S'inscrire</Link>
                </div>

            </div>
        </div>
    )
}

export default Signin